import {
  notificationAPI,
  notificationAPISingleChat,
} from "../../../services/notification.service";

const notificationSend = (values) => {
  notificationAPI(values);
};

const notificationSendSingleChat = (values) => {
  notificationAPISingleChat(values);
};

export { notificationSend, notificationSendSingleChat };
