import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../components/Layouts/BaseLayout";
import Loading from "../components/loading";
import UnauthenticatedLoading from "../components/loading/UnauthenticatedLoading";
import ScrollTop from "../components/ScrollTop/ScrollTop";
import { PROVIDERS } from "../hooks/useOauth2";
import { CallbackPop } from "../pages/SignIn/Callbacks/CallbackPop";
import TitleWrapper from "./TitleWrapper";

const AuthenticatedRoutes = React.lazy(() => import("./AuthenticatedRoutes"));
const UnauthenticatedRoutes = React.lazy(() => import("./UnauthenticatedRoutes"));


/**
 * Routing index file
 * All authenticated routes fall under the path /app
 * unauthenticated routes are kept separately outside /app path
 * @returns {JSX.Element}
 * @constructor
 */
const RouteList = () => {
//to prevent the header and footer while social login- loading.. time - for unauthenticated routes
const [loading, setLoading]=useState(false)

    return (
        <>
            <ScrollTop />
            <Routes path="/" element={<BaseLayout />}>
                <Route
                    path="app/*"
                    element={
                        <React.Suspense fallback={<Loading />}>
                            <AuthenticatedRoutes />
                        </React.Suspense>
                    }
                />
                {/* tiktok callback */}
                <Route path="/tiktok/callback/" element={
                    <React.Suspense fallback={<Loading />}>
                        <TitleWrapper title={`Signin | tiktok | Callback`}> <CallbackPop provider={PROVIDERS.TIKTOK} /></TitleWrapper>
                    </React.Suspense>
                } />
                {/* twitter callback */}
                <Route path="/twitter/callback/" element={
                    <React.Suspense fallback={<Loading />}>
                        <TitleWrapper title={`Signin | twitter | Callback`}> <CallbackPop provider={PROVIDERS.TWITTER} /></TitleWrapper>
                    </React.Suspense>
                } />

                <Route path="*" element={<React.Suspense fallback={<UnauthenticatedLoading setLoading={setLoading} loading={loading} />}>
                    <UnauthenticatedRoutes loading={loading} />
                </React.Suspense>} />

            </Routes>
        </>
    );
};

export default RouteList;
