const getOneSignalConfig = () => {
    const commonConfig = {
      apiUrl: process.env.REACT_APP_API_URL,
      oneSignalAppId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
    };
  
    let environmentConfig;
  
    switch (process.env.REACT_APP_ENV) {
      case 'development':
      case 'qa':
      case 'production':
        environmentConfig = commonConfig;
        break;
      default:
        throw new Error('Invalid environment');
    }
  
    return environmentConfig;
};

export {getOneSignalConfig}
  