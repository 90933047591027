import log from "loglevel";
import { getMainCarImage } from "../../../common/util";
import { generateCode } from "../../../components/reusable/HashCode/generateHash";
import { text2num } from "../../../components/reusable/TextToNum";
import { convertFirebaseTimeStamp } from "../../../components/reusable/TimeFormat";
import { turnaryCondition } from "../../../components/reusable/TurnaryCondition";
import { DEALER_ADMIN, PRIVATE, SWAP } from "../../../lib/constants";
import {
  bulkUpdateSubCollection,
  bulkUpdateSubCollectionSubId,
  checkBlockExists,
  createSubcollectionWithId,
  updateDocument,
} from "../../../lib/firebase-query";
import {
  createTransactions,
  updateTransactionsStatus,
} from "../../../services/car.service";
import { notificationSend } from "../Notifications/Notification";

export function getReceiverImg(user, userId) {
  if (user.userType === PRIVATE) {
    if (user._id !== userId) {
      return user?.trader?.logo ? user.trader.logo : user.userImage;
    }
  } else {
    return user?.trader?.logo ? user.trader.logo : user.userImage;
  }
}

export function getSenderImg(user, userId) {
  if (user.userType === PRIVATE) {
    if (user._id === userId) {
      return user.logo;
    }
  } else {
    return user?.trader?.logo ? user.trader.logo : user.userImage;
  }
}

export function checkIfvaluePresent(val) {
  if (!val) {
    return "";
  }
  return val;
}

export function generatePartnerJson(user) {
  if (
    Object.keys(user).includes("userId") &&
    Object.keys(user).includes("userName") &&
    Object.keys(user).includes("userImage") &&
    Object.keys(user).includes("userType")
  ) {
    return user;
  }
  return {
    rating: user.userType === PRIVATE ? null : user.trader.companyRating,
    userId: user.userType === PRIVATE ? user._id : user.traderId,
    userName:
      user.userType === PRIVATE ? user.userName : user.trader.companyName,
    userImage:
      user.userType === PRIVATE
        ? checkIfvaluePresent(user.logo)
        : checkIfvaluePresent(user.trader.logo),
    userType: user.userType === PRIVATE ? PRIVATE : DEALER_ADMIN,
    status: "active",
  };
}

export function generateCarJson(car, partnerId) {
  let carImage = getMainCarImage(car?.medias);
  return {
    carCash: car.userExpectedValue ? car.userExpectedValue : car.carCash,
    carId: car._id ? car._id : car.carId,
    carImage: car.carImage || carImage,
    carModel: car.model ? car.model : car.carModel,
    carName: car?.manufacturer?.name ? car?.manufacturer?.name : car.carName,
    userId: partnerId,
    status: "active",
  };
}

export const isSameDay = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const getImageFromUser = (user, userId, defaultImage = "no-user") => {
  if (defaultImage === "default") {
    return user.car.carImage;
  } else if (user.admin.userId === userId) {
    if (user.car.userId === userId) {
      return user.receiver.userImage;
    } else {
      return user.car.carImage;
    }
  } else if (user.receiver.userId === userId) {
    if (user.car.userId === userId) {
      return user.admin.userImage;
    } else {
      return user.car.carImage;
    }
  }
};

export const getUserName = (user, userId) => {
  if (user.car.userId === userId) {
    if (user.admin.userId === userId) {
      return user.receiver.userName;
    }
    if (user.receiver.userId === userId) {
      return user.admin.userName;
    }
  } else if (user.car.carName && user.car.carModel) {
    return user.car.carName;
  } else {
    return user.car.model;
  }
};

export const getCarName = (user) => {
  if (user.car.carName && user.car.carModel) {
    return user.car.carName + " - " + user.car.carModel;
  } else {
    return user.car.model + " - " + user.car.manufacturer.name;
  }
};

export const getOnlyCarName = (user) => {
  if (user.car.carName) {
    return user.car.carName;
  } else {
    return user.car.model;
  }
};

export const getOnlyModelName = (user) => {
  if (user.car.carName && user.car.carModel) {
    return user.car.carModel;
  } else {
    return user.car.manufacturer.name;
  }
};

export const getOnlyUserName = (user, userId) => {
  if (user.admin.userId === userId) {
    return user.receiver.userName;
  }
  if (user.receiver.userId === userId) {
    return user.admin.userName;
  }
};

export const getUserDetails = (user, userId) => {
  if (user.admin.userId === userId) {
    return user.receiver.userName
      ? user.receiver.userName
      : user.car.carModel?.substring(0, 4) + "" + user.groupId?.substring(0, 5);
  }
  if (user.receiver.userId === userId) {
    return user.admin.userName
      ? user.admin.userName
      : user.car.carModel?.substring(0, 4) + "" + user.groupId?.substring(0, 5);
  }
};

export const getUserRating = (user, userId) => {
  if (user?.admin?.userId === userId) {
    return user.receiver.rating;
  }
  if (user?.receiver?.userId === userId) {
    return user.admin.rating;
  }
};

export const getTraderId = (user) => {
  return (
    user.car.carModel?.substring(0, 4) + "" + user.groupId?.substring(0, 5)
  );
};

export const payDifference = (pay_diff, user, partner, userId) => {
  if (pay_diff) {
    if (pay_diff === "pay you") {
      if (user.userId === userId) {
        return user.userId;
      } else {
        return partner.userId;
      }
    } else if (pay_diff === "pay me") {
      if (user.userId === userId) {
        return partner.userId;
      } else {
        return user.userId;
      }
    } else {
      return null;
    }
  }
  return null;
};

export const MaskPasswordAndEmail = (chat) => {
  // Phone number masking
  const phoneNumberRegex =
    /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;
  let phoneNumberMasked = chat;

  // Check phone logic in different variable
  phoneNumberMasked = phoneNumberMasked
    .replace(/[^A-Z0-9]+/gi, "")
    .replace(phoneNumberRegex, (_match, _p1, _p2, _p3) => {
      return `**********`;
    });
  // if logic worked check with minimum value and append to chat
  if (phoneNumberMasked.includes("********")) {
    chat = phoneNumberMasked;
  }
  // Email masking
  const regex = /\b[A-Za-z0-9._%+-\s]+@[A-Za-z0-9.-\s]+\.[A-Z|a-z]{2,}\b/g;
  chat = chat.replace(regex, (match) => {
    const mask = "*".repeat(match.length - 1); // create a mask with '*' except last 5 characters
    return mask; // concatenate mask and last 5 characters
  });

  log.debug("Number", text2num(chat));
  // Alphabets capture
  if (text2num(chat) > 100000000) {
    // Need to convert the words to numbers
    chat = chat.toLocaleLowerCase().replace(numericalRegexPattern, "*");
    // remove special characters
    chat = chat.replace(/[!@#$%^&\\/)(+=._,-]+/g, "");
    // check if number is present again
    chat = chat.replace(/\d/g, "*");
  }

  // Number with special symbol
  let new_regex =
    /^(?=.*\d[\d\s!@#$%^&*\\/)(+=._,-]{9})[\d\s!@#$%^&*\\/)(+=._,-]{11,}$/g;
  let numberMasked = text2num(chat);

  if (numberMasked > 0) {
    numberMasked = numberMasked.toString();
    numberMasked = numberMasked?.replace(new_regex, (match) => {
      const mask = "*".repeat(match.length - 1); // create a mask with '*' except last 5 characters
      // const lastFiveChars = match.slice(-3); // get last 5 characters of the match
      return mask;
    });
    if (numberMasked.includes("********")) {
      chat = numberMasked;
    }
  }

  return chat;
};

const createTransactionAsync = async ({
  cars,
  userId,
  partnerId,
  userDetails,
  partner,
  carList,
  type,
  cash,
  payById,
}) => {
  try {
    return await createTransactions({
      seller:
        cars.userId !== userId
          ? {
              id: partnerId,
              type: partner.userType === PRIVATE ? "private" : "dealer",
            }
          : {
              id: userId,
              type: userDetails.userType === PRIVATE ? "private" : "dealer",
            },
      buyer:
        cars.userId === userId
          ? {
              id: partnerId,
              type: partner.userType === PRIVATE ? "private" : "dealer",
            }
          : {
              id: userId,
              type: userDetails.userType === PRIVATE ? "private" : "dealer",
            },

      sellerCarId: cars.carId,
      buyerCarIds: carList?.map(({ carId }) => ({ id: carId })) || [],
      type: type,
      amount: type === SWAP ? undefined : cash,
      payBy:
        type === SWAP
          ? undefined
          : turnaryCondition(cars.userId === payById, "seller", "buyer"),
      status: "active",
      createdBy: userId,
    });
  } catch (error) {
    log.error("createTransactionAsync error", error);
    throw error;
  }
};

const createChatData = ({
  userId,
  groupId,
  generateCode,
  actualUserId,
  carList,
  type,
  chat,
  pay_diff,
  transaction,
  userDetails,
  partner,
}) => {
  let offerJson = {
    offerStatus: "active",
    offerType: type,
    cash: chat ? parseInt(chat) : null,
    payById: payDifference(pay_diff, userDetails, partner, userId),
    transferSummaryId: transaction._id ? transaction._id : "",
  };
  if (carList) {
    offerJson["cars"] = carList;
  }
  return {
    createdAt: new Date(),
    updatedAt: new Date(),
    groupId: groupId,
    id: generateCode(),
    payload: {
      offer: offerJson,
    },
    type: "offer",
    sentBy: "web",
    createdById: userId,
    readUsers: [],
    updatedById: userId,
    actualCreatedUserId: actualUserId || null,
    status: "active",
    buyerCarIds: carList?.map(({ carId }) => carId) || [],
  };
};

export const handleOfferFor = async ({
  cash,
  type,
  carList,
  pay_diff,
  userId,
  cars,
  partner,
  userDetails,
  groupId,
  partnerId,
  doc,
  actualUserId,
  template_id,
}) => {
  log.debug("object", carList, cars);

  // Mask cash if it's not empty
  let chats = cash !== "" ? MaskPasswordAndEmail(cash) : "";

  try {
    const transaction = await createTransactionAsync({
      cars,
      userId,
      partnerId,
      userDetails,
      partner,
      carList,
      type,
      cash,
      payById: payDifference(pay_diff, userDetails, partner, userId),
    });
    log.debug("transaction", transaction);

    const chatData = createChatData({
      userId,
      groupId,
      generateCode,
      actualUserId,
      carList,
      type,
      chat: chats,
      pay_diff,
      transaction: transaction.createTransaction,
      userDetails,
      partner,
    });

    // Cancel active offers in bulk
    await bulkUpdateSubCollection(
      "chat_info",
      groupId,
      "chats",
      ["active", "accepted"],
      {
        "payload.offer.offerStatus": "canceled",
        updatedById: userId,
        updatedAt: new Date(),
      },
    );

    // Create a new chat with the offer
    await createSubcollectionWithId(
      "chat_info",
      groupId,
      "chats",
      chatData.id,
      chatData,
    )
      .then((res) => {
        log.debug(res);
      })
      .catch((err) => {
        log.error("err", err);
      });

    // Update the chat_info document
    await updateDocument("chat_info", groupId, {
      updatedAt: new Date(),
      lastMessage: "",
      isChatAvailable: true,
      lastUpdatedById: userId,
    });

    // Send a notification if needed
    const receiverId = partner.userId;

    if (
      !doc?.mutedUsers?.includes(receiverId) &&
      !doc?.blockedUsers?.includes(receiverId)
    ) {
      // send private or dealer according to the receiver user type
      const userType = partner.userType === PRIVATE ? "private" : "dealer";
      notificationSend({
        user_id: userId,
        user_type: userType,
        receiver_id: cars.userId,
        data: { groupId: groupId },
        template_id: template_id,
        image_url: cars.carImage,
      });
    }
  } catch (error) {
    log.error("handleOfferFor error", error);
  }
};

export const cancelAllOffers = (groupId, userId, chatId = null) => {
  return bulkUpdateSubCollectionSubId("chat_info", groupId, "chats", chatId, {
    "payload.offer.offerStatus": "canceled",
    "payload.offer.isRateChanged": true,
    updatedById: userId,
    updatedAt: new Date(),
  });
};
export const getChatStatusMessage = (status) => {
  if (status !== "active") {
    switch (status) {
      case "sold":
        return "Car has been sold";
      case "deleted":
        return "Profile has been deleted";
      case "carUnlisted":
        return "Car has been unlisted";
      case "carDeleted":
        return "Car has been deleted";
      case "profileDeleted":
        return "Profile has been deleted";
      case "profileUpgraded":
        return "Profile has been upgraded";
      case "profileInactive":
        return "Profile has been inactive";
      default:
        return "";
    }
  }
  return "";
};

export const notificationUserSelect = (admin, receiver, userId) => {
  if (admin.userId === userId) {
    return receiver.userType === PRIVATE ? "private" : "dealer";
  } else {
    return admin.userType === PRIVATE ? "private" : "dealer";
  }
};

export const findOutIfItsMe = (id, user) => {
  if (user.userType === PRIVATE) {
    return id === user.id;
  } else {
    return id === user.traderId;
  }
};

export const findOutIfItsMeFromFirebase = (id, user) => {
  return id === user.userId;
};

export const handleGroupStatusButton = (status) => {
  if (status !== "active") {
    switch (status) {
      case "sold":
        return "Sold"; // Once car has been sold
      case "deleted":
        return "Deleted"; // For future use
      case "carUnlisted":
        return "Car unlisted"; // car Un listed
      case "carDeleted":
        return "Car deleted"; // car Deleted
      case "profileDeleted":
        return "Profile deleted"; // profile deleted
      case "profileUpgraded":
        return "Profile upgraded"; // profile upgraded
      case "profileInactive":
        return "Profile inactive"; // Profile inactive
      default:
        return "";
    }
  }
  return "";
};

export const getAllCarsConditions = (mainCar, carList, status) => {
  let allSoldCars = [];
  if (mainCar?.status === status) {
    allSoldCars = [...allSoldCars, mainCar];
  }
  carList?.map((car) => {
    if (car?.status === status) {
      allSoldCars = [...allSoldCars, car];
      return car;
    }
    return null;
  });
  return allSoldCars;
};

export const getAllCarsConditionsById = (mainCar, carList, id) => {
  let allSoldCars = [];
  if (mainCar?.carId === id) {
    allSoldCars = [...allSoldCars, mainCar];
  }
  carList?.map((car) => {
    if (car?.carId === id) {
      allSoldCars = [...allSoldCars, car];
      return car;
    }
    return null;
  });
  return allSoldCars;
};

export const getAllCarsConditionsByIds = (mainCar, carList, data) => {
  // Extract carIds from the provided JSON
  const ids = data.map((item) => item.carId);
  let allSoldCars = [];

  // Check if mainCar's carId is in the array of ids
  if (ids.includes(mainCar?.carId)) {
    // Append data to mainCar
    const matchingData = data.find((item) => item.carId === mainCar.carId);
    if (matchingData) {
      mainCar = { ...mainCar, ...matchingData };
    }
    allSoldCars = [...allSoldCars, mainCar];
  }

  // Filter carList to include only cars with ids in the array
  carList?.forEach((car) => {
    if (ids.includes(car?.carId)) {
      // Append data to car
      const matchingData = data.find((item) => item.carId === car.carId);
      if (matchingData) {
        car = { ...car, ...matchingData };
      }
      allSoldCars = [...allSoldCars, car];
    }
  });

  return allSoldCars;
};

export const sortedChatList = (chatList) => {
  let new_list = chatList.sort((a, b) => b.createdAt - a.createdAt);
  return new_list;
};

export const sortedUserList = (userList) => {
  let new_list = userList.sort((a, b) => b.updatedAt - a.updatedAt);
  return new_list;
};

export const sortedUserListWithDeleteCheck = (userList, userId) => {
  let new_list = userList.sort((a, b) => b.updatedAt - a.updatedAt);

  // Use filter instead of map to filter out elements based on the condition
  let filteredList = new_list.filter((user) => {
    if (user?.deletedChat?.[userId]) {
      const deletedAt = convertFirebaseTimeStamp(user?.deletedChat[userId]);
      const latestMessageDate = convertFirebaseTimeStamp(user?.updatedAt);
      const isdeleted = deletedAt > latestMessageDate;
      if (isdeleted) {
        log.debug({
          latestMessageDate,
          deletedAt,
          isdeleted: deletedAt > latestMessageDate,
        });
        return false;
      }
    }
    return true;
  });

  return filteredList;
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const isWordNumeric = (str) => {
  // Regular expression to match a single number or a valid set of numbers
  const numericRegex =
    /^(?:\b\d+\b|\b(one|two|three|four|five|six|seven|eight|nine|ten)\b)$/gi;
  return numericRegex.test(str);
};

export const ReplaceEachChatWithStar = (chat) => {
  let check_if_num = isNumeric(chat);
  if (check_if_num) {
    let new_chat = "";
    let length = chat.length;
    for (let i = 0; i < length; i++) {
      new_chat = new_chat + "*";
    }
    return new_chat;
  } else {
    let word_condition = false;
    const words = chat.split(" "); // Split the chat into individual words
    let new_chat = "";
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (isNumeric(word) || isWordNumeric(word)) {
        // If the word is numeric or a valid word numeric, replace with stars
        new_chat += "*".repeat(word.length);
        word_condition = true;
      } else {
        // If the word is not numeric or a valid word numeric, keep it unchanged
        new_chat += word;
      }
      if (i !== words.length - 1) {
        // Add space after each word except the last one
        new_chat += " ";
      }
    }
    if (word_condition) {
      return new_chat;
    } else {
      return chat;
    }
  }
};

const regexPatterns = [
  /eleven(?:teen)?/,
  /twelve/,
  /thir(?:teen|ty)/,
  /four(?:teen|ty)/,
  /fif(?:teen|ty)/,
  /six(?:teen|ty)/,
  /seven(?:teen|ty)/,
  /eight(?:een|y)/,
  /nine(?:teen)?/,
  /twenty/,
  /thirty/,
  /forty/,
  /fifty/,
  /sixty/,
  /seventy/,
  /eighty/,
  /ninety/,
  /one|two|three|four|five|six|seven|eight|nine|zero/,
  /ten/,
];

export const numericalRegexPattern = new RegExp(
  `(?:${regexPatterns.map((pattern) => pattern.source).join("|")})`,
  "gi",
);

export const numericalRegexPatternWithWordBoundry = new RegExp(
  `\\b(?:${regexPatterns.map((pattern) => pattern.source).join("|")})\\b`,
  "gi",
);

export const getActorNameFromList = (
  admin,
  lastUpdatedById,
  user,
  receiver,
) => {
  if (admin.userId === lastUpdatedById) {
    return `${admin.userName} ${findOutIfItsMe(admin.userId, user) ? " (Me)" : ""}`;
  } else {
    return `${receiver.userName} ${findOutIfItsMe(receiver.userId, user) ? " (Me)" : ""}`;
  }
};

export const checkIfOverlayNeeded = (offer, groupstat) => {
  return groupstat === "active"
    ? !(offer === "active" || offer === "accepted")
    : !(groupstat === "sold" && offer === "accepted");
};

export const checkIfUserIsPrivate = (type) => {
  return type === PRIVATE;
};

export const updateAcceptStatusToCanceled = ({
  doc,
  transferSummaryId,
  groupId,
  chatId,
  message,
  userId,
  admin,
  receiver,
  car,
}) => {
  if (doc) {
    updateTransactionsStatus({
      id: transferSummaryId,
      amount: 0,
      status: "canceled",
    })
      .then((res) => {
        log.debug("rees", res);
      })
      .catch((err) => {
        log.error("eerr", err);
      });
    updateDocument(`chat_info/${groupId}/chats`, chatId, {
      payload: {
        offer: {
          ...message.offer,
          offerStatus: "canceled",
          isRateChanged: true,
        },
      },
      updatedById: userId,
      updatedAt: new Date(),
    })
      .then((res) => {
        log.debug("eres", res);
      })
      .catch((err) => {
        log.error("rerr", err);
      });
    updateDocument("chat_info", groupId, {
      lastUpdatedById: userId,
      updatedAt: new Date(),
    });
  }

  return "cancelled";
};

export const ConvertDateToString = (date) => {
  return convertFirebaseTimeStamp(date)?.toLocaleDateString();
};

export const renderDate = (date, previousDate, lastChat, indMatch) => {
  const currentDate = convertFirebaseTimeStamp(date);
  const prevDate = previousDate ? convertFirebaseTimeStamp(previousDate) : null;
  const today = new Date();
  const yesterday = new Date();
  const daybefore = new Date();
  yesterday.setDate(today.getDate() - 1);
  daybefore.setDate(today.getDate() - 2);

  if (prevDate === null || !isSameDay(currentDate, prevDate)) {
    if (isSameDay(currentDate, today)) {
      // if Today is shown at the starting place
      return indMatch ? <span className="date">Today</span> : null;
    } else if (isSameDay(currentDate, yesterday)) {
      return <span className="date">Yesterday</span>;
    } else {
      return (
        <span className="date">{currentDate.toLocaleDateString("en-GB")}</span>
      );
    }
  } else {
    if (lastChat) {
      if (isSameDay(currentDate, today)) {
        return <span className="date">Today</span>;
      } else if (isSameDay(currentDate, yesterday)) {
        return <span className="date">Yesterday</span>;
      }
      return (
        <span className="date">{currentDate.toLocaleDateString("en-GB")}</span>
      );
    }
    return null;
  }
};

export const generateChatInfoSchema = async ({
  currentUserId,
  receiverId,
  car,
  actualUserId,
  groupId,
  currentUserInfo,
  receiverInfo,
  mode = "MAKE_AN_OFFER",
}) => {
  const { blockedUsers } = await checkBlockExists({
    currentUserId,
    receiverId,
  });
  let groupInfo = {
    admin: currentUserInfo,
    receiver: receiverInfo,
    car: car,
    deletedChat: {},
    isChatAvailable: false,
    clearChat: {},
    blockedUsers,
    actualCreatedUserId: actualUserId || null,
    groupId: groupId,
    groupName: `${car.carName}-${car.carModel}`,
    groupNameForSearch: `${car.carName}-${car.carModel}`.toLocaleLowerCase(),
    groupUsers: [currentUserId, receiverId],
    chatUsers: {
      [currentUserId]: true,
      [receiverId]: true,
    },
    lastMessage: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    createdFrom: "web",
    status: "active",
    buyerCarIds: [],
  };
  if (mode === "LIKED_PAGE") {
    groupInfo = {
      ...groupInfo,
      groupAdminUserId: receiverId,
      isCarOwnerCreatedGroup: true,
    };
  } else {
    groupInfo = {
      ...groupInfo,
      groupAdminUserId: currentUserId,
    };
  }
  return groupInfo;
};

export function mapOfferStatus(inputStatus) {
  switch (inputStatus) {
    case "accepted":
      return "ACCEPTED_OFFER";
    case "rejected":
      return "DECLINED_OFFER";
    case "negotiating":
      return "NEGOTIATED_OFFER";
    default:
      return inputStatus; // Return the input status if it doesn't match any of the defined cases
  }
}

export const maskConsecutiveDigits = (inputData) => {
  const consecutiveDigitsRegex = /(?<!\d)\d(?:\W*\d){9,}/g;
  inputData = inputData.replace(consecutiveDigitsRegex, (match) => {
    const sanitizedMatch = match.startsWith("+") ? match.slice(1) : match;
    // Replace digits with '*', removing special characters and spaces
    const masked = sanitizedMatch.replace(/\d/g, "*").replace(/[^\w*]/g, "");
    return masked;
  });

  const maskedFollowedByDigitsRegex = /(\*{1,5}[\s.-]*)(\d+)/g;
  inputData = inputData.replace(
    maskedFollowedByDigitsRegex,
    (match, p1, p2) => {
      return p1 + p2.replace(/\d/g, "*"); // Preserve the masked part and mask new digits
    },
  );
  return inputData;
};

//Masking sequence of 6 or more digits in the word format
export const maskConsecutiveDigitsInWordForm = (inputData) => {
  const resultArr = [];
  let lastIndex = 0;
  let currentSequenceLength = 0;
  let firstMatchIndex = -1;

  inputData.replace(numericalRegexPatternWithWordBoundry, (match, index) => {
    if (currentSequenceLength === 0) {
      firstMatchIndex = index;
    }
    currentSequenceLength++;
    return match;
  });

  if (currentSequenceLength > 9) {
    inputData.replace(numericalRegexPatternWithWordBoundry, (match, index) => {
      const spaceRegex = /^\s*$/;
      const firstMatch = inputData.slice(lastIndex, index);
      const isOnlySpace = spaceRegex.test(firstMatch);
      if (!isOnlySpace) {
        resultArr.push(firstMatch);
      }
      resultArr.push("*"); //replace the match with single asterik

      const lastIndexOfCurrentMatch = index + match.length;
      lastIndex = lastIndexOfCurrentMatch;
      return match;
    });
  } else {
    resultArr.push(inputData.slice(firstMatchIndex, lastIndex));
    return inputData;
  }
  //push the remaining text after last match
  resultArr.push(inputData.slice(lastIndex));
  return resultArr.join("");
};

export const maskEmail = (inputData) => {
  const emailRegex = /\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/;
  inputData = inputData.replace(emailRegex, (match) => {
    return "*".repeat(match.length);
  });
  return inputData;
};

export const maskConsecutiveDigitsStatus = (inputData) => {
  const moreThanOneSpecCharsDigitsRegex = /\d(?:[^\da-zA-Z]*\d){9,}/;
  const moreThanOneSpecCharsDigits =
    moreThanOneSpecCharsDigitsRegex.test(inputData);
  return moreThanOneSpecCharsDigits;
};

export const maskConsecutiveDigitsInWordFormStatus = (inputData) => {
  let currentSequenceLength = 0;
  inputData.replace(numericalRegexPatternWithWordBoundry, (match, index) => {
    currentSequenceLength++;
    return match;
  });

  return currentSequenceLength > 9;
};

export const maskEmailStatus = (inputData) => {
  const emailRegex = /\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/;
  return emailRegex.test(inputData);
};
