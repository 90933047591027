import { API } from "aws-amplify";

const paymentApi = async (props) => {
  const apiName = "AuthenticatedApi";
  const path = "/payments/payment/checkout";
  const myInit = {
    body: props,
    headers: {},
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

export { paymentApi };
