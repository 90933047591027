import * as chatTypes from "./types";

const initialState={
    chatType: "",
    chatStatus: "",
    chatRedirect:"",
    pageName: "",
    carId: "",
    showLoading:false,
    chatCarSlugId: "",
    chatUserlist: [],
    chatNotification: false,
    chatNotificationButton: false,
    chatGroupInfo:{
        selectedUserId:"",
        userDetails:{},
        cars:[],
        chatUser:"",
        groupId:"",
        partner:{},
        actualUserId:"",
        groupDetails:{},
    }
}

const chatReducer = (state=initialState, action={})=>{
    switch(action.type){
        case chatTypes.SET_CHATS_DATA:
            return {
                ...state,
                ...action.payload
            }
        case chatTypes.LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: action.payload,
            };        
        case chatTypes.SET_CHATS_TYPE:
            return {
                ...state,
                chatType: action.payload,
            }
        case chatTypes.SET_CHATS_STATUS:
            return {
                ...state,
                chatStatus: action.payload,
            }
        case chatTypes.SET_CHATS_REDIRECT:
            return {
                ...state,
                chatRedirect: action.payload,
            }
        case chatTypes.SET_CHATS_PAGES:
            return {
                ...state,
                pageName: action.payload,
            }
        case chatTypes.SET_CHATS_CARSLUGID:
            return {
                ...state,
                chatCarSlugId: action.payload,
            }
        case chatTypes.SET_CHATS_USERLIST:
            return {
                ...state,
                chatUserlist: action.payload,
            }
        case chatTypes.SET_CHATS_NOTIFICATION:
            return {
                ...state,
                chatNotification: action.payload
            }
        case chatTypes.SET_CHATS_NOTIFICATION_BUTTON:
            return {
                ...state,
                chatNotificationButton: action.payload
            }
        case chatTypes.CLEAR_CHATS:
            localStorage.removeItem("chatData");
            return {
                chatType: "",
                chatStatus: "",
                chatRedirect:"",
                pageName: "",
                carId: "",
                showLoading:false,
                chatCarSlugId: "",
                chatNotification: false,
            };
        default:
            return state;
        }
    
    }
    
 export default chatReducer;