import React from 'react'
import PropTypes from "prop-types";

const loader = () => {
    return <span className="loader"></span>
}
const Button = ({ id, disabled, loading, className, onClick, type, children ,style,title, hidden=false}) => {
    return (
        <button id={id} disabled={disabled || loading}
            className={`custom-btn ${className || ""}`}
            onClick={onClick}
            title={title}
            type={type}
            style={style}
            hidden={hidden}>
            
            {loading ? loader() : children}
        </button>
    )
}

Button.propTypes = {
    id: PropTypes.string, 
    disabled: PropTypes.bool, 
    loading: PropTypes.bool, 
    className: PropTypes.string, 
    onClick: PropTypes.func, 
    type: PropTypes.string,
    children: PropTypes.node, 
    style: PropTypes.object, 
    title:PropTypes.string,
    hidden: PropTypes.bool
};
export default Button;
