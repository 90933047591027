import gql from "graphql-tag";

export const addedAccessories = gql`
  query MyQuery($id: String!) {
    getCarDetails(key: "_id", value: $id) {
      addedAccessories {
        listed {
          name
        }
        notListed {
          name
        }
      }
    }
  }
`;

export const getAccessoriesData = gql`
  query MyQuery {
    getAccesoryData {
      _id
      name
      sortOrder
      status
    }
  }
`;

export const hpiMotDetailsApi = gql`
  query MyQuery($id: String!) {
    getCarDetails(key: "_id", value: $id) {
      hpi {
        firstRegisted
        historyCheck {
          v5cDataQty
          vehicleIdentityCheckQty
          KeeperChangesQty
          colourChangesQty
          financeDataQty
          cherishedDataQty
          conditionDataQty
          stolenVehicleDataQty
          highRiskDataQty
          isScrapped
        }
        keeperStartDate
        lastMotDate
        numberOfKeys
        onFinance
        previousOwner
        privatePlate
        sellerKeepingPlate
        vin
      }
    }
  }
`;

export const getVehicleRegId = gql`
  query MyQuery($id: String!) {
    getCarDetails(key: "_id", value: $id) {
      registration
      exteriorGrade {
        grade
        id
      }
    }
  }
`;
export const getLikedCars = gql`
  query MyQuery(
    $pageNo: Int!
    $perPage: Int!
    $userId: String!
    $filter: LikedCarFilter
    $sort: Int = -1
  ) {
    likedCars(
      pageNo: $pageNo
      perPage: $perPage
      userId: $userId
      sortBy: $sort
      filters: $filter
    ) {
      cars {
        yearOfManufacture
        wsacValue
        userType
        userName
        companyName
        companyRating
        companyLogo
        userExpectedValue
        updatedBy
        updatedAt
        status
        transmissionType {
          id
          name
        }
        additionalInformation {
          attentionGraber
        }
        catDetails {
          status
          key
        }
        registration
        rating
        chatUserId
        quickSale
        postType
        ownerId
        ownerType
        model
        mileage
        manufacturer {
          id
          name
        }
        likedBy
        fuelType {
          id
          name
        }
        description
        createdAt
        companyDescription
        carName
        carId
        bodyType {
          id
          name
        }
        avatarImage
        _id
        likes
        image {
          id
          src
          validationStatus
          toBeDeleted
        }
        analytics {
          views
          likes
        }
        carDescription
        openToSwap
        carLocation{
          coordinates
          type
        }
      }
      totalPages
      totalRecords
      pageNo
    }
  }
`;
export const getOtherUserCarList = gql`
  query MyQuery(
    $pageNo: Int!
    $perPage: Int!
    $userIdOrTraderId: String!
    $userType: mainUserTypes!
    $filters: MyCarListingFilters
  ) {
    getOtherUserCarsList(
      pageNo: $pageNo
      perPage: $perPage
      userIdOrTraderId: $userIdOrTraderId
      userType: $userType
      filters: $filters
    ) {
      totalPages
      totalRecords
      pageNo
      cars {
        _id
        slug
        mileage
        manufacturer {
          id
          name
        }
        fuelType {
          id
          name
        }
        medias {
          rightImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          leftImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          frontImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rearImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          interiorImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          bootSpaceImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          bulkImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          adittionalImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          videos {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        analytics {
          likes
          views
          offersReceived
          matches
          opens
        }
        tradeValue
        wsacValue
        userExpectedValue
        priceApproved
        quickSale
        model
        yearOfManufacture
        userId
        userType
        status
        postType
        surveyQuestions
        ownerProfileImage
        ownerUserName
        doors
        dealerId
        numberOfGears
        numberOfSeats
        companyName
        companyRating
        companyLogo
        soldDate
        createdAt
        updatedAt
        updatedBy
        listedDate
        carDescription
        openToSwap
        carLocation{
          coordinates
          type
        }
      }
    }
  }
`;

export const getCarsDetailsData = gql`
  query MyQuery($id: String!) {
    getCarDetails(key: "_id", value: $id) {
      _id
      model
      mileage
      slug
      registration
      userId
      dealerId
      wsacValue
      userType
      ownerUserName
      ownerProfileImage
      manufacturer {
        id
        name
      }
      status
      yearOfManufacture
      quickSale
      userExpectedValue
      companyName
      companyLogo
      companyRating
      uploadPhotos {
        rightImages
      }
      medias {
        rightImages {
          id
          src
          validationStatus
          toBeDeleted
          mainImage
        }
        leftImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        frontImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        rearImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        interiorImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        bootSpaceImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        bulkImages {
          id
          src
          validationStatus
          toBeDeleted
          mainImage
        }
        adittionalImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        videos {
          id
          src
          validationStatus
          toBeDeleted
        }
      }
    }
  }
`;
export const getPhotosAndVideos = gql`
  query MyQuery($id: String!) {
    getCarDetails(key: "_id", value: $id) {
      carImages
      carVideos
    }
  }
`;

export const getCarSummaryDetails = gql`
  query MyQuery($key: String!, $carSlugId: String!) {
    getCarDetails(key: $key, value: $carSlugId) {
      _id
      yearOfManufacture
      engineSize
      exteriorGrade {
        grade
        id
      }
      hpiAndMot {
        firstRegisted
        historyCheck {
          vehicleIdentityCheckQty
          KeeperChangesQty
          colourChangesQty
          financeDataQty
          cherishedDataQty
          conditionDataQty
          stolenVehicleDataQty
          highRiskDataQty
          isScrapped
        }
        keeperStartDate
        lastMotDate
        numberOfKeys
        onFinance
        previousOwner
        privatePlate
        sellerKeepingPlate
        vin
      }
      registration
      conditionAndDamage {
        additionalInfo
        scratches {
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
          scratches
        }
        warningLightsDashboard {
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
          warningLightsDashboard
        }
        dents {
          dents
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
          images
        }
        paintProblem {
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
          paintProblem
        }
        brokenMissingItems {
          brokenMissingItems
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        smokingInVehicle {
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
          smokingInVehicle
        }
        toolPack {
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
          toolPack
        }
        tyreProblem {
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
          tyreProblem
        }
        scuffedAlloy {
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
          scuffedAlloy
        }
        lockWheelNut {
          images
          lockWheelNut
        }
      }
      fuelType {
        name
        id
      }
      mileage
      model
      registration
      colour {
        name
        id
      }
      transmissionType {
        name
        id
      }
      manufacturer {
        name
        id
      }
      addedAccessories {
        listed {
          exist
          id
          name
        }
        notListed {
          name
        }
      }
      userExpectedValue
      tradeValue
      serviceHistory {
        medias {
          id
          src
          validationStatus
          toBeDeleted
        }
        independent
        mainDealer
        record
      }
      ownerProfileImage
      ownerUserName
      companyLogo
      companyRating
      userId
      dealerId
      additionalInformation {
        attentionGraber
        companyDescription
        description
      }
      quickSale
      uploadPhotos {
        adittionalImages
        bootSpaceImages
        frontImages
        interiorImages
        leftImages
        rearImages
        rightImages
        videos
      }
      ownerUserName
      companyName
      ownerProfileImage
      priceApproved
      tradeValue
      openToSwap
      userExpectedValue
      userId
      userType
      wsacValue
      status
      slug
      postType
      doors
      catDetails {
        status
        key
      }
      carLocation {
        coordinates
        type
      }
      bodyType {
        id
        name
      }
      analytics {
        likes
        views
        offersReceived
        matches
        opens
      }
      carDescription
      validationStatus {
        status
        conditionAndDamage
        medias
        serviceHistory
        listingStatus
      }
      premiumPost {
        isPremium
        status
      }
      medias {
        adittionalImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        bootSpaceImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        frontImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        interiorImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        leftImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        rearImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        rightImages {
          id
          src
          validationStatus
          toBeDeleted
          mainImage
        }
        bulkImages {
          id
          src
          validationStatus
          toBeDeleted
          mainImage
        }
        videos {
          id
          src
          validationStatus
          toBeDeleted
        }
      }
      approvedRateChangeRequest {
        userExpectedValue
        updatedAt
      }
    }
  }
`;

export const getActiveUserCount = gql`
  query MyQuery {
    activeUsersCount {
      count
    }
  }
`;

export const getExteriorGrade = gql`
  query MyQuery {
    getExteriorGrade {
      _id
      grade
      percentageValue
    }
  }
`;
export const getWheelsAndTyre = gql`
  query MyQuery($id: String!) {
    getCarDetails(key: "_id", value: $id) {
      wheelsAndTyres {
        lockWheelNut
        scuffedAlloy
        toolPack
        tyreProblem
        tyreImage {
          eTag
          url
          key
          size
          lastModified
        }
        wheelImage {
          eTag
          url
          key
          size
          lastModified
        }
      }
    }
  }
`;

export const getAdditinalIngormationData = gql`
  query MyQuery($key: String!, $carSlugId: String!) {
    getCarDetails(key: $key, value: $carSlugId) {
      description
      companyDescription
      attentionGrabber
    }
  }
`;

export const getCarCondition = gql`
  query MyQuery($id: String!) {
    getCarDetails(key: "_id", value: $id) {
      conditionAndDamage {
        additionalInfo
        brokenMissingItems {
          brokenMissingItems
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        dents {
          dents
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        paintProblem {
          paintProblem
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        scratches {
          scratches
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        smokingInVehicle {
          smokingInVehicle
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        warningLightsDashboard {
          warningLightsDashboard
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        toolPack {
          toolPack
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        lockWheelNut {
          lockWheelNut
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        tyreProblem {
          tyreProblem
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        scuffedAlloy {
          scuffedAlloy
          images
          medias {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
      }
    }
  }
`;

export const carValueLimit = gql`
  query MyQuery {
    getSystemConfigurations(key: "priceApprovePercentage")
  }
`;

export const getTransmissionData = gql`
  query MyQuery {
    getTransmissionTypes {
      _id
      createStatus
      quickSale
      fuelType {
        name
      }
      analytics {
        likes
        offersReceived
        views
      }
      serviceHistory {
        record
      }
      userExpectedValue
      model
      mileage
      postType
      manufacturer {
        name
        id
      }
      uploadPhotos {
        adittionalImages
        bootSpaceImages
        interiorImages
        leftImages
        frontImages
        rearImages
        rightImages
        videos
      }
      medias {
        rightImages {
          id
          src
          validationStatus
          toBeDeleted
          mainImage
        }
        leftImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        frontImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        rearImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        interiorImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        bootSpaceImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        adittionalImages {
          id
          src
          validationStatus
          toBeDeleted
        }
        bulkImages {
          id
          src
          validationStatus
          toBeDeleted
          mainImage
        }
        videos {
          id
          src
          validationStatus
          toBeDeleted
        }
      }
      name
    }
  }
`;

export const getManufactureData = gql`
  query MyQuery($source:String, $userId:String) {
    getManufacturers(source:$source, userId:$userId) {
      name
      _id
    }
  }
`;

export const myCars = gql`
  query MyCars($pageNo: Int!, $perPage: Int!, $filter: MyCarListingFilters) {
    myCars(pageNo: $pageNo, perPage: $perPage, filters: $filter) {
      cars {
        _id
        createStatus
        quickSale
        colour {
          name
        }
        transmissionType {
          name
        }
        engineSize
        registration
        exteriorGrade {
          grade
        }
        fuelType {
          name
        }
        analytics {
          likes
          offersReceived
          views
        }
        serviceHistory {
          record
        }
        hpiAndMot {
          lastMotDate
        }
        userExpectedValue
        tradeValue
        wsacValue
        model
        userId
        userType
        mileage
        postType
        openToSwap
        yearOfManufacture
        additionalInformation {
          attentionGraber
        }
        manufacturer {
          name
          id
        }
        uploadPhotos {
          adittionalImages
          bootSpaceImages
          interiorImages
          leftImages
          frontImages
          rearImages
          rightImages
          videos
        }
        premiumPost {
          expiry
          isPremium
          transactionId
          amount
          status
        }
        PremiumPostLogs {
          amount
        }
        status
        listedDate
        soldDate
        carDescription
        catDetails {
          status
          key
        }
        validationStatus {
          status
        }
        medias {
          adittionalImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          bootSpaceImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          frontImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          interiorImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          leftImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rearImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rightImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          videos {
            id
            src
            validationStatus
            toBeDeleted
          }
          bulkImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
        }
      }
      totalPages
      totalRecords
      pageNo
    }
  }
`;

export const expiredPremiumCars = gql`
  query MyQuery($pageNo: Int!, $perPage: Int!) {
    expiredPremiumCars(pageNo: $pageNo, perPage: $perPage, sortBy: -1) {
      cars {
        _id
        createStatus
        quickSale
        fuelType {
          name
        }
        analytics {
          likes
          offersReceived
          views
        }
        serviceHistory {
          record
        }
        userExpectedValue
        model
        mileage
        postType
        status
        manufacturer {
          name
          id
        }
        uploadPhotos {
          adittionalImages
          bootSpaceImages
          interiorImages
          leftImages
          frontImages
          rearImages
          rightImages
          videos
        }
        medias {
          rightImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          leftImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          frontImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rearImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          interiorImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          bootSpaceImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          adittionalImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          bulkImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          videos {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
        premiumPost {
          expiry
          isPremium
          transactionId
          amount
          status
        }
      }
      totalPages
      totalRecords
      pageNo
    }
  }
`;

export const filterData = gql`
  query MyQuery($filterData: Boolean) {
    listMasterTechnicalDetails(filterData: $filterData) {
      bodyTypes {
        _id
        name
      }
      carColors {
        colorCode
        name
        _id
      }
      doors
      fuelTypes {
        _id
        name
        sortOrder
        fuelGroup
      }
      manufacturers {
        _id
        name
        models {
          _id
          manufacturer
          name
        }
      }
      transmissionTypes {
        _id
        name
      }
      transmissionTypesGrouped {
        _id
        name
      }
      fuelTypesGrouped {
        _id
        name
      }
      tradeType
    }
  }
`;

export const getCars = gql`
  query MyQuery(
    $filter: ListingFilters
    $filterRecentCars: Boolean
    $pageNo: Int
    $paginationKey: String
  ) {
    getCars(
      listingParams: {
        filterRecentCars: $filterRecentCars
        filters: $filter
        pageNo: $pageNo
        paginationKey: $paginationKey
      }
    ) {
      cars {
        _id
        additionalInformation {
          attentionGraber
          companyDescription
          description
        }
        colour {
          name
        }
        engineSize
        fuelType {
          name
        }
        hpiAndMot {
          firstRegisted
          registration
        }
        mileage
        model
        ownerProfileImage
        ownerUserName
        companyName
        companyLogo
        companyRating
        carLocation{
          coordinates
          type
        }
        postType
        quickSale
        registration
        yearOfManufacture
        openToSwap
        catDetails {
          status
          key
        }
        transmissionType {
          name
        }
        userExpectedValue
        userId
        userType
        analytics {
          likes
          views
        }
        premiumPost {
          isPremium
          status
        }
        uploadPhotos {
          adittionalImages
          bootSpaceImages
          frontImages
          interiorImages
          leftImages
          rearImages
          rightImages
          videos
        }
        manufacturer {
          name
        }
        carDescription
        medias {
          adittionalImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          bootSpaceImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          frontImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          interiorImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          leftImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rearImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rightImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          bulkImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          videos {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
      }
      pageNo
      paginationKey
      totalPages
      totalRecords
    }
  }
`;

export const otherCarsByUser = `query MyQuery($userId:String!,$exclude: String!,$pageNo: Int!, $perPage:Int!,$filters: ListingFilters ) {


  otherCarsByUser(exclude: $exclude, userId: $userId ,pageNo:$pageNo, perPage:$perPage ,filters:$filters ) {
    pageNo
    totalPages
    totalRecords
    cars {
      _id
      uploadPhotos {
        adittionalImages
        bootSpaceImages
        frontImages
        interiorImages
        leftImages
        rearImages
        rightImages
        videos
      }
      analytics {
        likes
        views
      }
      quickSale
      dealerId
      userId
      userExpectedValue
      userType
      ownerProfileImage
      ownerUserName
      companyName
      companyLogo
      companyRating
      yearOfManufacture
      additionalInformation {
        attentionGraber
      }
      manufacturer {
        name
      }
      model
      serviceHistory {
        record
      }
      fuelType {
        name
      }
      mileage
      carDescription
      openToSwap
      catDetails {
        status
        key
      }

      postType
      premiumPost{
        isPremium
        status
      }
      medias {
          adittionalImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          bootSpaceImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          frontImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          interiorImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          leftImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rearImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rightImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          bulkImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          videos {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
    }
  }
 }
`;
export const listCars = gql`
  query MyQuery(
    $pageNo: Int!
    $perPage: Int!
    $sort: Int = -1
    $filter: ListingFilters
  ) {
    listCars(
      pageNo: $pageNo
      perPage: $perPage
      filters: $filter
      sortBy: $sort
    ) {
      cars {
        _id
        createStatus
        quickSale
        ownerUserName
        yearOfManufacture
        fuelType {
          name
        }
        analytics {
          likes
          offersReceived
          views
        }
        serviceHistory {
          record
        }
        userExpectedValue
        model
        mileage
        postType
        openToSwap
        hpiAndMot {
          registration
          vin
        }
        additionalInformation {
          attentionGraber
        }
        companyName
        companyRating
        companyLogo
        userType
        userId
        dealerId
        carLocation{
          coordinates
          type
        }
        manufacturer {
          name
          id
        }
        uploadPhotos {
          adittionalImages
          bootSpaceImages
          interiorImages
          leftImages
          frontImages
          rearImages
          rightImages
          videos
        }
        premiumPost {
          isPremium
          status
        }
        catDetails {
          status
          key
        }
        carDescription
        medias {
          adittionalImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          bootSpaceImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          frontImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          interiorImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          leftImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rearImages {
            id
            src
            validationStatus
            toBeDeleted
          }
          rightImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          bulkImages {
            id
            src
            validationStatus
            toBeDeleted
            mainImage
          }
          videos {
            id
            src
            validationStatus
            toBeDeleted
          }
        }
      }
      totalPages
      totalRecords
      pageNo
    }
  }
`;
export const getPremiumStatusDetails = gql`
  query MyQuery($id: String!) {
    getCarDetails(key: "_id", value: $id) {
      premiumPost {
        expiry
        isPremium
      }
      postType
    }
  }
`;
export const getListMaster = gql`
  query MyQuery {
    listMasterTechnicalDetails {
      bodyTypes {
        _id
        name
        slug
        status
        createdAt
      }
      carColors {
        colorCode
        name
        status
        updatedAt
        updatedBy
      }
      doors
      fuelTypes {
        _id
        name
        sortOrder
        status
        fuelGroup
      }
      manufacturers {
        _id
        models {
          manufacturer
          name
          slug
          status
        }
        name
        sortOrder
        status
      }
      tradeType
      transmissionTypes {
        _id
        name
        status
        transmissionGroup
      }
    }
  }
`;

export const premiumPaymentHistory = gql`
  query MyQuery($id: String!) {
    getCarDetails(key: "_id", value: $id) {
      PremiumPostLogs {
        amount
        transactionId
        createdAt
        status
      }
    }
  }
`;

export const DashboardMyCars = gql`
  query MyQuery($pageNo: Int!, $perPage: Int!, $filter: MyCarListingFilters) {
    myCars(pageNo: $pageNo, perPage: $perPage, filters: $filter) {
      cars {
        _id
        manufacturer {
          name
          id
        }
        model
        tradeValue
        userExpectedValue
        yearOfManufacture
      }
      pageNo
      paginationKey
      totalPages
      totalRecords
    }
  }
`;

export const getMyMatches = gql`
  query MyQuery(
    $pageNo: Int!
    $perPage: Int!
    $sort: Int = -1
    $userId: String!
  ) {
    getMyMatches(
      pageNo: $pageNo
      perPage: $perPage
      sortBy: $sort
      userId: $userId
    ) {
      myMatchesList {
        cars1 {
          _id
          mileage
          model
          userExpectedValue
          wsacValue
          avatarImage
          registration
          yearOfManufacture
          manufacturer {
            name
          }
          rating
          image {
            id
            src
            validationStatus
            toBeDeleted
          }
          ownerId
          carId
          carDescription
        }
        cars2 {
          _id
          mileage
          model
          userExpectedValue
          wsacValue
          avatarImage
          registration
          ownerId
          ownerType
          companyLogo
          companyName
          companyRating
          userName
          yearOfManufacture
          manufacturer {
            name
          }
          rating
          image {
            id
            src
            validationStatus
            toBeDeleted
          }
          ownerId
          carId
          carDescription
        }
      }
      pageNo
      totalPages
      totalRecords
    }
  }
`;

export const getCarWithIssues = `query MyQuery($pageNo: Int!, $perPage: Int!) {
  carsWithIssues(pageNo: $pageNo, perPage: $perPage) {
    paginationKey
    pageNo
    totalPages
    totalRecords
    cars {
      _id
      mileage
      premiumPost {
        expiry
      }
      userExpectedValue
      tradeValue
      uploadPhotos {
        rightImages
      }
      medias{
        rightImages{
            id
            src
            validationStatus
            toBeDeleted
            mainImage
        }
        leftImages{
            id
            src
            validationStatus
            toBeDeleted
        }
        frontImages{
            id
            src
            validationStatus
            toBeDeleted
        }
        rearImages{
            id
            src
            validationStatus
            toBeDeleted
        }
        interiorImages{
            id
            src
            validationStatus
            toBeDeleted
        }
        bootSpaceImages{
            id
            src
            validationStatus
            toBeDeleted
        }
        adittionalImages{
            id
            src
            validationStatus
            toBeDeleted
        }
        bulkImages {
          id
          src
          validationStatus
          toBeDeleted
          mainImage
        }
        videos{
            id
            src
            validationStatus
            toBeDeleted
        }
      }
      model
      registration
      status
      analytics {
        likes
        views
      }
      manufacturer {
        name
      }
      postType
      listedDate
      createStatus
      userId
      userType
    }
  }
}`;

export const exportStockList = gql`
  query MyQuery($filter: MyCarListingFilters) {
    exportStockList(filters: $filter) {
      message
    }
  }
`;
export const getStockPrintList = gql`
  query MyCars($filter: MyCarListingFilters) {
    getStockPrintList(filters: $filter) {
      cars {
        _id
        model
        status
        manufacturer {
          name
          id
        }
        registration
        userExpectedValue
        tradeValue
        status
        listedDate
      }
    }
  }
`;
export const makeDataFilterQuery = gql`
  query MyQuery($filters: ListingFilters) {
    makeFilterData(filters: $filters) {
      count
      make
      makeId
      models {
        count
        model
      }
    }
    topMakesFilterData(filters: {}) {
      count
      make
      makeId
    }
  }
`;

export const getMaxAndMinPriceFilter = gql`
  query MyQuery($filters: ListingFilters) {
    priceMaxMinFilterData(filters: $filters) {
      maxPrice
      minPrice
    }
  }
`;

export const getBodyTypes = gql`
  query MyQuery($filters: ListingFilters) {
    bodyTypeFilterData(filters: $filters) {
      count
      bodyType
      bodyId
    }
  }
`;

export const getFuelTypes = gql`
  query MyQuery($filters: ListingFilters) {
    fuelTypeFilterData(filters: $filters) {
      count
      fuelType
      fuelId
    }
  }
`;

export const getAvailableCarCount = gql`
  query MyQuery($filters: ListingFilters) {
    carCountWithFilterData(filters: $filters) {
      count
    }
  }
`;

export const getAvailableColors = gql`
  query MyQuery($filters: ListingFilters) {
    colourFilterData(filters: $filters) {
      count
      colour
      colourId
    }
  }
`;

export const getMileageQuery = gql`
  query MyQuery($filters: ListingFilters) {
    mileageMinMaxFilterData(filters: $filters) {
      minMileage
      maxMileage
    }
  }
`;

export const getEngineSizeQuery = gql`
  query MyQuery($filters: ListingFilters) {
    engineSizeMinMaxFilterData(filters: $filters) {
      maxEngineSize
      minEngineSize
    }
  }
`;

export const getYearFilterQuery = gql`
  query MyQuery($filters: ListingFilters) {
    yearMinMaxFilterData(filters: $filters) {
      maxYear
      minYear
    }
  }
`;
