import gql from "graphql-tag";

export const getAddCarDetails = gql`
    query GetAddCarPreview($id: String!) {
        getCarDetails(key: "_id", value: $id) {
            _id
            registration
            slug
            mileage
            exteriorGrade{
                id
                grade
            }
            tradeValue
            wsacValue
            userExpectedValue
            priceApproved
            quickSale
            additionalInformation{
                attentionGraber
                description
                companyDescription
            }
            addedAccessories{
                listed{
                    id
                    exist
                    name
                }
                notListed{
                    name
                }
            }
            manufacturer{
                id
                name
            }
            model
            yearOfManufacture
            colour{
                id
                name
            }
            transmissionType{
                id
                name
            }
            engineSize
            fuelType{
                id
                name
            }

            hpiAndMot{
                historyCheck {
                    v5cDataQty
                    vehicleIdentityCheckQty
                    KeeperChangesQty
                    colourChangesQty
                    financeDataQty
                    cherishedDataQty
                    conditionDataQty
                    stolenVehicleDataQty
                    highRiskDataQty
                    isScrapped
                  }
                registration
                vin
                keeperStartDate
                firstRegisted
                lastMotDate
                previousOwner
                isPreOwnerNotDisclosed
                numberOfKeys
                onFinance
                privatePlate
                sellerKeepingPlate
            }
            serviceHistory{
                record
                mainDealer
                independent
                images
                medias{
                    id
                    src
                    validationStatus
                    toBeDeleted
                }
            }
            conditionAndDamage{
                scratches{
                    scratches
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                dents{
                    dents
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                paintProblem{
                    paintProblem
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                smokingInVehicle{
                    smokingInVehicle
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                brokenMissingItems{
                    brokenMissingItems
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                warningLightsDashboard{
                    warningLightsDashboard
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                tyreProblem{
                    tyreProblem
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                scuffedAlloy{
                    scuffedAlloy
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                toolPack{
                    toolPack
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                lockWheelNut{
                    lockWheelNut
                    images
                    medias {
                        id
                        src
                        validationStatus
                        toBeDeleted
                    }
                }
                additionalInfo
            }
            uploadPhotos{
                rightImages
                leftImages
                frontImages
                rearImages
                interiorImages
                bootSpaceImages
                adittionalImages
                videos
            }
            medias{
                bulkImages{
                    id
                    src
                    validationStatus
                    toBeDeleted
                    mainImage
                }
                rightImages{
                    id
                    src
                    validationStatus
                    toBeDeleted
                    mainImage
                }
                leftImages{
                    id
                    src
                    validationStatus
                    toBeDeleted
                }
                frontImages{
                    id
                    src
                    validationStatus
                    toBeDeleted
                }
                rearImages{
                    id
                    src
                    validationStatus
                    toBeDeleted
                }
                interiorImages{
                    id
                    src
                    validationStatus
                    toBeDeleted
                }
                bootSpaceImages{
                    id
                    src
                    validationStatus
                    toBeDeleted
                }
                adittionalImages{
                    id
                    src
                    validationStatus
                    toBeDeleted
                }
                videos{
                    id
                    src
                    validationStatus
                    toBeDeleted
                }
            }
#            carLocation{
#                type
#                coordinates
#            }
            bodyType {
                id
                name
            }
            doors
            createStatus
            status
            #            userType: carUserType
            #            postType: carPostType
            createdAt
            updatedAt
            validationStatus {
                status
                serviceHistory
                conditionAndDamage
                medias
            }
            approvedRateChangeRequest {
                userExpectedValue
                updatedAt
            }
            userId
            dealerId
            openToSwap
        }
    }
`;

export const getCarListingProps = gql`
    query MyQuery {
        listMasterTechnicalDetails {
            bodyTypes {
                _id
                name
            }
            carColors {
                _id
                name
            }
            doors
            fuelTypes {
                _id
                name
            }
            manufacturers {
                _id
                name
            }
            transmissionTypes {
                _id
                name
            }
        }
    }
`;