import { useEffect, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { checkState, getUrl, isWindowOpener, openerPostMessage } from '../../../lib/tools';
import Loading from "../../../components/loading";
import { PROVIDERS } from '../../../hooks/useOauth2';
import { INVALID_CODE, OAUTH_RESPONSE } from '../../../lib/constants';
import Message from '../../../common/errorMessages.json';

const getErrorMessage = (err, stateOk) => {
    if (err) {
        return decodeURI(err)
    }
    return stateOk ? 'OAuth error: State mismatch.' : err
}
export const CallbackPop = ({ provider }) => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [params] = useSearchParams();
    const [err, setError] = useState(null)

    const scanParams = useCallback(() => {
        if (params.get('error') || params.get('denied')) {
            setError(params.get('error_description') || Message.SOCIAL_LOGIN_ERROR)
            return false
        }

        if (provider === PROVIDERS.TWITTER) {
            if (!params.get('oauth_token') && !params.get('oauth_verifier')) {
                setError(INVALID_CODE)
                return false
            }
        }
        if (provider === PROVIDERS.TIKTOK) {
            if (!params.get('code')) {
                setError(INVALID_CODE)
                return false
            }
        }
        return true
    })
    const validState = (state) => {
        return provider === PROVIDERS.TWITTER ? true : state && checkState(state);
    }
    const getToken = async () => {
        try {
            if (!scanParams()) {
                return;
            }
            setLoading(true)
            // send the code to the server
            const response = await fetch(`${getUrl(provider).token}?${new URLSearchParams(params).toString()}`)
            const data = await response.json();
            setLoading(false)
            if (response.ok) {
                setData(data?.data)
            } else {
                throw new Error(data.message)
            }
        } catch (err) {
          
            setError(err.message)
            setLoading(false)
        }
    }
    useEffect(() => {
        if (params) {
            getToken()
        }
    }, [params])

    useEffect(() => {
        if (data) {
            const state = params.get('state')
            if (isWindowOpener(window.opener)) {
                const stateOk = validState(state)
                if (stateOk) {
                    openerPostMessage(window.opener, {
                        type: OAUTH_RESPONSE,
                        payload: data,
                    });
                }
            } else {
                throw new Error('No window opener');
            }
        }
    }, [data]);

    useEffect(() => {
        if (err) {
            const state = params.get('state')
            const stateOk = validState(state)
            const errorMessage = getErrorMessage(err, stateOk)
            openerPostMessage(window.opener, {
                type: OAUTH_RESPONSE,
                error: errorMessage,
            });
        }
    }, [err])

    return (
        <>{loading && <Loading />}</>
    );
};