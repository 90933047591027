import React from "react";

export const InformationIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4609 19C15.4315 19 19.4609 14.9706 19.4609 10C19.4609 5.02944 15.4315 1 10.4609 1C5.49037 1 1.46094 5.02944 1.46094 10C1.46094 14.9706 5.49037 19 10.4609 19Z"
        fill=""
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4609 13.6V10"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4609 6.40002H10.4699"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
