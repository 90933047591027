import * as authTypes from "./types";

let loggedInUser = localStorage.getItem("loggedInUser") || null;
loggedInUser = loggedInUser !== null ? JSON.parse(loggedInUser) : null;

let wsac_web = localStorage.getItem("wsac_web") || null;
wsac_web = wsac_web !== null ? JSON.parse(wsac_web) : null;

const initialState={
    cognitoUser: null,
    authenticated: loggedInUser !== null ? true : false,
    token: wsac_web ? wsac_web.token : null,
    successModal:false,
    thanksModal:false,
    successModalCloseLocation:'',
    showLoading: false,
    kommunicateState:null
}

const authReducer = (state=initialState, action={})=>{
    switch(action.type){
        case authTypes.SET_AUTH_DATA:
            return {
                ...state,
                ...action.payload
            }
        case authTypes.USER_AUTHENTICATED:
            return {
                ...state,
                cognitoUser: null,
                authenticated: true,
                user: action.payload,
                successModal:action.modalStatus,
                successModalCloseLocation:action.location,
                showLoading:false
            };
        case authTypes.SHOW_SUCCESS_MODAL:
            return{
                    ...state,
                    successModal:action.payload,
                    successModalCloseLocation:action.location
                }
        case authTypes.SHOW_THANKS_MODAL:
            return{
                    ...state,
                    thanksModal:action.payload,
                    thanksModalCloseLocation:action.location
                }
        
        case authTypes.LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: action.payload,
            };
        case authTypes.USER_SIGNOUT:
            localStorage.clear();
            return {
                user: null,
                cognitoUser: null,
                token: null,
                authenticated: false,
            };
        default:
            return state;
    }

}

export default authReducer;