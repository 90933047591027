import * as carTypes from "./types";

const initialState={
    setWheelsTyreDetails: {},
    setMotDetails:{},
    additionalViewFilterData: {}
}

const carReducer = (state=initialState, action={})=>{
    switch(action.type){
    
        case carTypes.UPDATE_CAR_ID:
            return {
                ...state,
                setCarValuationCheck: {...state.setCarValuationCheck, carId:action.payload},
            }
        
        case carTypes.SET_MOT_DETAILS:
            return {
                ...state,
                setMotDetails: action.payload,
            }
        case carTypes.SET_WHEELS_TYRE_DETAILS:
            return {
                ...state,
                setWheelsTyreDetails: action.payload,
            }
            case carTypes.RESET_ADDITIONAL_FILTER_DATA:
                return {
                  ...state,
                  additionalViewFilterData: {},
                };
          
            case carTypes.UPDATE_ADDITIONAL_FILTER_DATA:
                return {
                  ...state,
                  additionalViewFilterData: {
                    ...state.additionalViewFilterData,
                    ...action.payload,
                  },
                };
          
        default:
            return state;
        }
    
    }
    
 export default carReducer;