import { dynamicNumberDropdown } from "../../../../lib/customFunctions"

export function BasicHpiHistory() {
    return {
        cherishedDataQty: {
          message: "Private plate not changed",
          problem: false,
        },
        colourChangesQty: {
          message: "Colour not changed",
          problem: false,
        },
        // conditionDataQty: {
        //   message: "Good condition",
        //   problem: false,
        // },
        financeDataQty: {
          message: "Not under finance",
          problem: false,
        },
        highRiskDataQty: {
          message: "No risk",
          problem: false,
        },
        // KeeperChangesQty: {
        //   message: "Keeper not changed",
        //   problem: false,
        // },
        stolenVehicleDataQty: {
          message: "Not stolen",
          problem: false,
        },
        isScrapped: {
          message: "Not scrapped",
          problem: false,
        },
        vehicleIdentityCheckQty: {
          message: "Identity not changed",
          problem: false,
        },
    }
}

export function GetHpiBasedOnCondition(car) {
    return {
        vehicle_identity_check_qty: 
          car?.hpiAndMot?.historyCheck?.vehicleIdentityCheckQty?
          car?.hpiAndMot?.historyCheck?.vehicleIdentityCheckQty: 
          car?.hpiAndMot?.historyCheck?.vehicle_identity_check_qty,
        keeper_changes_qty: 
          car?.hpiAndMot?.historyCheck?.KeeperChangesQty?
          car?.hpiAndMot?.historyCheck?.KeeperChangesQty:
          car?.hpiAndMot?.historyCheck?.keeper_changes_qty,
        colour_changes_qty: 
          car?.hpiAndMot?.historyCheck?.colourChangesQty?
          car?.hpiAndMot?.historyCheck?.colourChangesQty:
          car?.hpiAndMot?.historyCheck?.colour_changes_qty,
        finance_data_qty: 
          car?.hpiAndMot?.historyCheck?.financeDataQty?
          car?.hpiAndMot?.historyCheck?.financeDataQty:
          car?.hpiAndMot?.historyCheck?.finance_data_qty,
        cherished_data_qty: 
          car?.hpiAndMot?.historyCheck?.cherishedDataQty?
          car?.hpiAndMot?.historyCheck?.cherishedDataQty:
          car?.hpiAndMot?.historyCheck?.cherished_data_qty,
        condition_data_qty: 
          car?.hpiAndMot?.historyCheck?.conditionDataQty?
          car?.hpiAndMot?.historyCheck?.conditionDataQty:
          car?.hpiAndMot?.historyCheck?.condition_data_qty,
        stolen_vehicle_data_qty: 
          car?.hpiAndMot?.historyCheck?.stolenVehicleDataQty?
          car?.hpiAndMot?.historyCheck?.stolenVehicleDataQty:
          car?.hpiAndMot?.historyCheck?.stolen_vehicle_data_qty,
        high_risk_data_qty: 
          car?.hpiAndMot?.historyCheck?.highRiskDataQty?
          car?.hpiAndMot?.historyCheck?.highRiskDataQty:
          car?.hpiAndMot?.historyCheck?.high_risk_data_qty,
        is_scrapped: 
          car?.hpiAndMot?.historyCheck?.isScrapped?
          car?.hpiAndMot?.historyCheck?.isScrapped:
          car?.hpiAndMot?.historyCheck?.is_scrapped,
    }
}

export function convertDataToSchema(data, mot) {
    if(data?.vehicle_identity_check_qty){
        return {
            vehicleIdentityCheckQty: data.vehicle_identity_check_qty,
            // KeeperChangesQty: mot?.previousOwner || 0,
            colourChangesQty: data.colour_changes_qty,
            financeDataQty: data.finance_data_qty,
            cherishedDataQty: data.cherished_data_qty,
            // conditionDataQty: data.condition_data_qty,
            stolenVehicleDataQty: data.stolen_vehicle_data_qty,
            highRiskDataQty: data.high_risk_data_qty,
            isScrapped: data.is_scrapped,
            // v5cDataQty: data.v5cDataQty
        }
    }
    return data
}

export function convertSchemaToData(data) {
    if(data?.vehicleIdentityCheckQty){
        return {
            vehicle_identity_check_qty: data?.vehicleIdentityCheckQty,
            previousOwner: data?.KeeperChangesQty,
            colour_changes_qty: data?.colourChangesQty,
            finance_data_qty: data?.financeDataQty,
            cherished_data_qty: data?.cherishedDataQty,
            condition_data_qty: data?.conditionDataQty,
            stolen_vehicle_data_qty: data?.stolenVehicleDataQty,
            high_risk_data_qty: data?.highRiskDataQty,
            is_scrapped: data?.isScrapped,
        }
    }
    return data
}

export function setDefaultValues1(hpiDetails, car, HpiCheck){
  return {
    ...hpiDetails,
    historyCheck: HpiCheck?.message,
    hpi_check: car?.hpiAndMot?.historyCheck,
    keeperStartDate: car?.hpiAndMot?.keeperStartDate?new Date(car.hpiAndMot.keeperStartDate):null,
    vin: car?.hpiAndMot?.vin,
    firstRegisted: car?.hpiAndMot?.firstRegisted?new Date(car?.hpiAndMot?.firstRegisted):null,
    lastMotDate: car?.hpiAndMot?.lastMotDate?new Date(car.hpiAndMot.lastMotDate):null,
    previousOwner: car?.hpiAndMot?.previousOwner,
    isPreOwnerNotDisclosed: car?.hpiAndMot?.isPreOwnerNotDisclosed,
    numberOfKeys: car?.hpiAndMot?.numberOfKeys,
    onFinance: car?.hpiAndMot?.onFinance.toLowerCase(),
    privatePlate: car?.hpiAndMot?.privatePlate? "yes" : "no",
    sellerKeepingPlate: car?.hpiAndMot?.sellerKeepingPlate?"yes": "no",
  }
}

export function setDefaultValues2(hpiDetails, HpiCheck, hpi, localStoreCarDetails){
  return {
    ...hpiDetails,
    historyCheck: HpiCheck?.message,
    hpi_check: hpi.hpi_check,
    keeperStartDate: new Date(hpi?.hpi?.keeperStartDate),
    vin: hpi?.vin,
    firstRegisted: new Date(hpi?.firstRegistered),
    lastMotDate: new Date(hpi?.lastMotDate),
    previousOwner: hpi?.hpi?.previousOwner,
    numberOfKeys: localStoreCarDetails?.hpiAndMot?.numberOfKeys,
    onFinance: hpi?.hpi?.onFinance ? "yes" : "no",
    privatePlate: localStoreCarDetails?.hpiAndMot?.privatePlate,
    sellerKeepingPlate: localStoreCarDetails?.hpiAndMot?.sellerKeepingPlate?"yes": "no",
  }
}

export function setEmptyValues(hpiDetails){
  return {
    ...hpiDetails,
    historyCheck: "No Data Found",
    hpi_check: {},
    keeperStartDate: "",
    vin: "",
    firstRegisted: "",
    lastMotDate: "",
    previousOwner: 0,
    numberOfKeys: null,
    onFinance: null,
    privatePlate: null,
    sellerKeepingPlate: "no",
  }
}

export function generatePreviousValue(prevOwn){
  if (prevOwn){
    return [{value: 0, label:"Not Disclosed"}, {value: prevOwn, label: prevOwn} ]
  }else{
    return dynamicNumberDropdown(5)
  }
}