import { OAUTH_STATE_KEY, POPUP_HEIGHT, POPUP_WIDTH } from "./constants";

export const objectToQuery = (object) => {
    return new URLSearchParams(object).toString();
};


// https://medium.com/@dazcyril/generating-cryptographic-random-state-in-javascript-in-the-browser-c538b3daae50
export const generateState = () => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let array = new Uint8Array(40);
    window.crypto.getRandomValues(array);
    array = array.map((x) => validChars.codePointAt(x % validChars.length));
    const randomState = String.fromCharCode.apply(null, array);
    return randomState;
};

export const saveState = (state) => {
    sessionStorage.setItem(OAUTH_STATE_KEY, state);
};

export const removeState = () => {
    sessionStorage.removeItem(OAUTH_STATE_KEY);
};

export const checkState = (receivedState) => {
    const state = sessionStorage.getItem(OAUTH_STATE_KEY);
    return state === receivedState;
};

export const openPopup = (url) => {
    // To fix issues with window.screen in multi-monitor setups, the easier option is to
    // center the pop-up over the parent window.
    const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
    const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
    return window.open(
        url,
        'Social Login',
        `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
    );
};

export const closePopup = (popupRef) => {
    popupRef.current?.close();
};

export const isWindowOpener = (opener) =>
    opener !== null && opener !== undefined;

export const openerPostMessage = (opener, message) =>
    opener.postMessage(message);

export const cleanup = (
    intervalRef,
    popupRef,
    handleMessageListener
) => {
    clearInterval(intervalRef.current);
    if (popupRef.current && typeof popupRef.current.close === 'function') closePopup(popupRef);
    removeState();
    window.removeEventListener('message', handleMessageListener);
};

export const getUrl = (provider) => {
    const host = `${process.env.REACT_APP_API}/auth`
    return {
        oauth: `${host}/oauth/${provider?.toLowerCase()}/`,
        token: `${host}/${provider?.toLowerCase()}/callback/`
    }
}


export const getCurrentGroupId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const groupId = urlParams.get('groupId');
    return groupId

}