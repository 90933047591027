import React from "react";
import ErrorImg from "../../assets/images/404.svg";
import { DASHBOARD_HOME_ROUTE } from "../../lib/constants";
import { useNavigate } from "react-router-dom";

const SentryFallback = () => {
  const navigate = useNavigate();
  return (
    <section className="dashboard-content error-wrap ">
      <div className="container-wrap">
        <div className="error-block">
          <img src={ErrorImg} alt="" />
          <p>We're sorry, but it seems like we're experiencing technical difficulties on our website. Our developers are actively investigating the problem to get things back on track. In the meantime, please check back later or reach out to our support team for further assistance. Thank you for your understanding.</p>
          <button className='custom-btn' onClick={()=>navigate(DASHBOARD_HOME_ROUTE)}>Back To Dashboard</button>
        </div>
      </div>
    </section>
  );
};

export default SentryFallback;
