const SET_AUTH_DATA = "SET_AUTH_DATA";
const USER_AUTHENTICATED = "USER_AUTHENTICATED";
const USER_SIGNOUT = "USER_SIGNOUT";
const SHOW_SUCCESS_MODAL = "SHOW_SUCCESS_MODAL";
const SHOW_THANKS_MODAL = "SHOW_THANKS_MODAL";
const LOADING_TOGGLE_ACTION = "LOADING_TOGGLE_ACTION";

export {
  SET_AUTH_DATA,
  USER_AUTHENTICATED,
  USER_SIGNOUT,
  SHOW_SUCCESS_MODAL,
  SHOW_THANKS_MODAL,
  LOADING_TOGGLE_ACTION,
};
