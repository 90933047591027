const cloudfrontUrl = process.env.REACT_APP_CLOUDFRONT_DOMAIN
const s3url = process.env.REACT_APP_S3_DOMAIN

export const generateCloudfrontUrl = ({url, filetype = "image", format = "auto", width = "default"}) => {

    if (filetype === "video") {
        return url;
    }
    if (!url) {
        return require("../assets/images/no-car-img.png")
    }
    let optimizedUrl = url.replace(s3url, cloudfrontUrl)
    const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

    if(isSafari){
        return  optimizedUrl;
    }
    // Apply formatting
    optimizedUrl += "?format=" + format
    // Resize if required
    if (width !== "default") {
        optimizedUrl += "&width=" + width
    }
    return optimizedUrl

}