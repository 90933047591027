import * as likesActionTypes from "./types";

const initialState = {
  carId: '',
  carStatus: '',
  likedUsersList: [],
  likesModal: false,
  likesLoader: false,
  moreLikesLoader: false,
  pageType: '',
  pageNo: 0,
  totalRecords: 0,
};

const carLikesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case likesActionTypes.SET_LIKED_USERSLIST: {
      return {
        ...state,
        likedUsersList: state.likedUsersList.concat(action.payload.users),
        likesModal: true,
        likesLoader: false,
        moreLikesLoader: false,
        pageType: action.payload.pageType,
        pageNo: action.payload.pageNo,
        carId: action.payload.carId,
        carStatus: action.payload.carStatus,
        totalRecords: action.payload.totalRecords,
      }
    }
    case likesActionTypes.CLOSE_LIKES_MODAL:
      return {
        ...state,
        likesModal: action.payload,
        likedUsersList: [],
        totalRecords: 0,
      };
    case likesActionTypes.SET_LIKES_LOADER:
      return {
        ...state,
        likesLoader: action.payload.open,
        carId: action.payload.carId,
        pageType: action.payload.pageType
      };
    case likesActionTypes.SET_MORELIKES_LOADER:
      return {
        ...state,
        moreLikesLoader: action.payload,
      };
    default:
      return state;
  }
}

export default carLikesReducer;
