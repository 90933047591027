import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth/reducer';
import carReducer from './car/reducer';
import questionReducer from './questions/reducer';
import undoCountReducer from './viewMatches/reducer'
import chatReducer from './chat/reducer';
import dashboardReducer from './dashboard/reducer';
import carLikesReducer from './carLikes/reducer';


// const store=createStore(reducers)
const store=configureStore({
        reducer:{
            auth :authReducer,
            car : carReducer,
            question: questionReducer,
            undoCount: undoCountReducer,
            chat: chatReducer,
            dashboard: dashboardReducer,
            carLikes: carLikesReducer
        },
        middleware:getDefaultMiddleware =>
        getDefaultMiddleware({
          immutableCheck: false,
          serializableCheck: false,
        }),
    })

export default store