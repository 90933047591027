import React, { useEffect } from "react";
import Loader_image from "../../assets/images/Pulse-1s-200px.svg";
import PropTypes from "prop-types";

function UnauthenticatedLoading({width, style, className="loader-spinner", setLoading=()=>{}, loading}) {
    useEffect(()=>{
        setLoading(true)
        return ()=> setLoading(false)
    },[loading])
    return (
        <div className={className} style={style} ><img width={width} src={Loader_image} alt="Loading"/></div>
    )
}

UnauthenticatedLoading.propTypes={
    width:PropTypes.any,
    style:PropTypes.any,
    className:PropTypes.string,
    setLoading:PropTypes.func,
    loading:PropTypes.bool,
  }
export default UnauthenticatedLoading