import { toast } from 'react-toastify';
const showWarningToast=(msg)=>{
    const customId = 'toast-warn-id';
        toast.warning(msg,{
            toastId: customId
          });
    
}
const showErrorToast=(msg)=>{
    const customId = 'toast-err-id';
      toast.error(msg,{
        toastId: customId
      });
}
const showSuccessToast=(msg, toastID)=>{
  const customId = {toastId:'toast-succ-id'};
    toast.success(msg,toastID || customId);
}
const showInfoToast=(msg)=>{
    const customId = 'toast-info-id';
      toast.info(msg,{
        toastId: customId
      });
}

export {
    showWarningToast,
    showErrorToast,
    showSuccessToast,
    showInfoToast
}