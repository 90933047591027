import { RiWifiOffLine } from 'react-icons/ri';

const NoInternet=()=>{

return (
    <div className='no-internet-container'>
      <div className='no-internet-message'>
        <div className='icon-container'>
          <RiWifiOffLine size="8em" className='cloud-icon' color='#ED1155' />
        </div>
        <p>No internet connection</p>
        <span>It looks like you're currently offline.</span>
        <span>Please check your internet connection and try again.</span>
      </div>
    </div>
  );
};

export default NoInternet;