import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { getUserProfile } from "../../services/auth.service";
import { signInWithFireBase, signOut } from "../../redux/auth";
import InformationModal from "../../components/Modal/InformationModal";
import { toast } from "react-toastify";
import log from "loglevel";
import {
  CAR_VALUATION_CHECK_ROUTE,
  ACCOUNT_DEACTIVATED,
} from "../../lib/constants";
import {
  firebaseRealTimeListner,
  activePlanStatusCheck,
  checkRedirectToBaseRoute,
  checkingInternetConnection,
} from "../../lib/customFunctions";
import NoInternet from "../Common/Internet";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [social, setSocial] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [noConnection, setNoConnection] = useState(false);
  const [payAsYouGoActiveCount, setPayAsYouGoActiveCount] = useState(null);
  const currentPath = useLocation().pathname;

  const loadUserProfile = async () => {
    let authData;
    try {
      authData = await Auth.currentAuthenticatedUser();
      const { getUserDetails } = await getUserProfile({
        key: "cognitoId",
        value: authData?.signInUserSession?.idToken?.payload?.identities
          ? authData?.signInUserSession?.idToken?.payload?.sub //social users username is different
          : authData?.attributes?.sub,
      });

      if (getUserDetails) {
        // Login with firebase
        await signInWithFireBase();
        setUser(getUserDetails);
      }
      if (authData) {
        setSocial(authData);
      }
      if (
        getUserDetails?.status === "inactive" ||
        getUserDetails?.status === "adminDisabled"
      ) {
        setShow(true);
      }
    } catch (error) {
      log.error(error);
      const socialUser = authData || null;
      setUser(null);
      setSocial(socialUser);
    }
  };

  const userSignOutInactive = async () => {
    toast.error(ACCOUNT_DEACTIVATED);
    setShow(false);
    await signOut();
  };

  const loadData = async () => {
    await loadUserProfile();
  };
  useEffect(() => {
    (async () => {
      if (!user) {
        await loadData();
      }
      setIsLoading(false);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  const refreshContext = async () => {
    await loadUserProfile();
  };

  useEffect(() => {
    const collectionName = "Traders";
    const documentId = user?.traderId;
    // Add a listener to the collection
    if (documentId) {
      log.debug("firebaseRealTimeListner in authcontext ");
      firebaseRealTimeListner({
        collectionName,
        documentId,
        setPlanCancelStatus: () => null,
        setPayAsYouGoActiveCount,
      });
    }
    checkRedirectToBaseRoute({ user, currentPath, navigate });
  }, [user]);

  useEffect(() => {
    if (payAsYouGoActiveCount >= 1 && currentPath === "/app/subscription") {
      refreshContext();
      window.location.href = CAR_VALUATION_CHECK_ROUTE;
      return;
    }
    activePlanStatusCheck({
      currentPath,
      traderObj: user?.trader,
      navigate,
    });
  }, [payAsYouGoActiveCount]);

  const authContextValue = useMemo(
    () => ({
      refreshContext,
      user,
      isLoading,
      social,
    }),
    [refreshContext, user, isLoading, social],
  );

  useEffect(() => {
    const internetStatus = checkingInternetConnection();
    if (internetStatus) {
      setNoConnection(false);
    } else {
      setNoConnection(true);
    }
  }, [currentPath]);

  return (
    <>
      {noConnection ? (
        <NoInternet />
      ) : (
        <>
          <AuthContext.Provider value={authContextValue}>
            {children}
          </AuthContext.Provider>
          <InformationModal
            show={show}
            content={ACCOUNT_DEACTIVATED}
            label={"close"}
            action={() => userSignOutInactive()}
          />
        </>
      )}
    </>
  );
};

const useAuth = () => useContext(AuthContext);

export default AuthContext;
export { AuthProvider, useAuth };
