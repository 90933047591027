import log from "loglevel";
import { generateCloudfrontUrl } from "../lib/imageManager";
import { ADD_CAR_HOME_PAGE, ALLOWED_IMAGE_EXTENSIONS, CAR_VIEW_CAR_DETAIL, EDIT_CAR_HOME_PAGE } from "../lib/constants";
import { checkEditOrAdd } from "../lib/customFunctions";
import placeHolderImg from "../assets/images/image_not_found.svg";
export default class Util {
    static getCurrency(number, { minimumFractionDigits } = { minimumFractionDigits: 0 }) {
        const formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits
        });
        return number !== undefined ? formatter.format(number) : ''
    }
    static attachIds(array) {
        return array.map((a, i) => ({ value: a, id: i }))
    }
}

export const arrayEquals = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export const ConvertDateToAwsDateFormat = (date) => {
    return new Date(date).toISOString().slice(0, 10)
}


export const flatImageArray = ({ mediaList = [], isLiveListing = false }) => {
    if (isLiveListing) {
        mediaList = mediaList.filter(media => (!["rejected", "pending", "inProgress"].includes(media.validationStatus)))
    }
    // Set main image object to first position
    mediaList=mediaList.sort((x,y)=>y.mainImage-x.mainImage)
    return mediaList.map(media => {
        return {src: media.src, status: media.validationStatus}
    })
}

/**
 * Method converts image object to array containing media files only as per the system order
 * @param carImages
 * @returns {*[]}
 */
export const mapImageObjToArray = (carImages = {}, isLiveListing = false) => {
    let op = []
    const { bulkImages = [], rightImages = [], leftImages = [], interiorImages = [], frontImages = [], bootSpaceImages = [], adittionalImages = [], rearImages = [], videos = [] } = carImages
    if (bulkImages?.length) {
        op = [...op, ...(flatImageArray({ mediaList: bulkImages, isLiveListing }))]
    }
    if (rightImages?.length) {
        op = [...op, ...(flatImageArray({ mediaList: rightImages, isLiveListing }))]
    }
    if (leftImages?.length) {
        op = [...op, ...(flatImageArray({ mediaList: leftImages, isLiveListing }))]
    }
    if (frontImages?.length) {
        op = [...op, ...(flatImageArray({ mediaList: frontImages, isLiveListing }))]
    }
    if (rearImages?.length) {
        op = [...op, ...(flatImageArray({ mediaList: rearImages, isLiveListing }))]
    }
    if (interiorImages?.length) {
        op = [...op, ...(flatImageArray({ mediaList: interiorImages, isLiveListing }))]
    }
    if (bootSpaceImages?.length) {
        op = [...op, ...(flatImageArray({ mediaList: bootSpaceImages, isLiveListing }))]
    }
    if (adittionalImages?.length) {
        op = [...op, ...(flatImageArray({ mediaList: adittionalImages, isLiveListing }))]
    }

    if (videos?.length) {
        op = [...op, ...(flatImageArray({ mediaList: videos, isLiveListing }))]
    }

    let outputWithKey;
    if (videos?.length) {

        outputWithKey = op.map((url) => ({
            url: generateCloudfrontUrl({
                url: url.src,
                width: "744",
                format: "webp",
                filetype: "video",
            }),
            key: op.indexOf(url.src),
            status: url.status
        }));
    } else {

        outputWithKey = op.map((url, index) => ({
            url: generateCloudfrontUrl({
                url: url.src,
                width: "744",
                format: "webp",
                filetype: "image",
            }),
            key: index,
            status: url.status
        }));
    }

    // const outputWithKey=op.map((url,index)=>({url,key:index}))
    // log.debug({carImages,op,outputWithKey})
    return outputWithKey

}

export const getMainCarImage = (images = []) => {
    let mainImage = placeHolderImg;
    let imageList=[]
    // Take images from bulk/right fields if available
    if (images?.bulkImages?.length) {
        imageList = images?.bulkImages;
    } else if (images?.rightImages?.length) {
        imageList = images?.rightImages;
    }
    if(imageList.length){
        // Sort to obtain mainImage as first image
        imageList = imageList.sort((x,y)=>y.mainImage-x.mainImage)
        mainImage = imageList[0].src
    }
    return mainImage;
}

export const isNumeric = (num) => {
    let keys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
    return keys.includes(num) || (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);
}

export const dateFormat = (date) => {
    const incomingDate = new Date(date);
    const formattedDate = `${incomingDate.getDate().toString().padStart(2, '0')}-${(incomingDate.getMonth() + 1).toString().padStart(2, '0')}-${incomingDate.getFullYear()}`;
    return formattedDate
}

export const getCurrentPath = () => {
    return window.location.pathname + window.location.search;
}

export const saveEditCarSourcePath = (props = getCurrentPath()) => {
    localStorage.setItem("EditCarSourcePath", props);
}

export const getEditCarSourcePath = () => {
    return localStorage.getItem("EditCarSourcePath");
}



/**
 * This function takes a number and returns a formatted string representation of the number.
 *
 * @param {number} value - The number to be formatted.
 * @returns {string} - The formatted string representation of the number.
 */
export const countView = (value) => {
    switch (true) {
        case value > 1000000:
            return `${(value / 1000000).toFixed(1)}M`.replace(".0", "");
        case value === 1000000:
            return "1M";
        case value > 1000:
            return `${(value / 1000).toFixed(1)}K`.replace(".0", "");
        case value === 1000:
            return "1K";
        default:
            return value
    }
}


/**
 * Function to share a car
 * @param {string} carId - The ID of the car to be shared
 * @param {function} setShareUrl - The function to set the share URL
 * @param {function} setSharePopup - The function to set the share popup visibility
 */
export const shareCar = (carId, setShareUrl, setSharePopup) => {
    const url = window.location.origin + CAR_VIEW_CAR_DETAIL + "/" + carId;
    const shareUrl = `${url}`;
    setShareUrl(shareUrl);
    setSharePopup(true);
};

/**
 * Check whether current filename is having valid image extension
 * @param fileName
 * @returns {{extension: string, isValidFileExtension: boolean, error: string}}
 */
export const isValidImageFileExtension = (fileName = "") => {
    const extension = fileName.split('.').pop().toLowerCase();
    //Invalid file - ${extension} files are not supported
    return { isValidFileExtension: ALLOWED_IMAGE_EXTENSIONS.includes(extension), extension, error: `The uploaded file is not supported. Please choose a JPEG, JPG, or PNG format.` }

}

/**
 * Fn makes string array unique by removing duplicates
 * @param array
 * @returns {array}
 */
export const removeArrayDuplicates = (array) => {
    // Convert to unique set
    let uniqueSet = new Set(array);

    // Convert the Set back to an array & return
    return Array.from(uniqueSet);
}

export const isImageUploadOptionAvailable = (medias = [], NO_OF_IMG = 4) => {
    const deletedScheduledRecords = medias.filter(i => i?.toBeDeleted === true) || []
    const slotsCount = NO_OF_IMG + deletedScheduledRecords.length
    return medias.length < slotsCount
}

export const filterDeletedImagesFromList = (medias = []) => {
    return medias.filter(i => i?.toBeDeleted !== true)
}

export const filterSensitiveImagesFromList = (medias = []) => {
    return medias.filter(i => i?.validationStatus === "rejected")
}

export const checkSensitiveContentExists = (medias = []) => {
    return medias.filter(i => i?.validationStatus === "rejected" && !i?.toBeDeleted).length
}

export const filterInvalidImagesFromList = (medias = []) => {
    medias = medias.filter(i => i?.validationStatus !== "rejected")
    medias = medias.filter(i => !i?.toBeDeleted)
    return medias
}

export const redirectToStepperScreen = (navigate) => {
    const urlParams = new URLSearchParams(window.location.search);
    const carId = urlParams.get('id');
    const currentPage = checkEditOrAdd();
    let redirectPath = ADD_CAR_HOME_PAGE
    if (currentPage === "edit") {
        redirectPath = EDIT_CAR_HOME_PAGE

    }
    navigate(`${redirectPath}?id=${carId}`)
}

export const filterImagesForListing = (medias = [], mode = "live") => {
    // if live listing allow only approved images
    if (mode === "live") {
        medias = medias.filter(i => i?.validationStatus === "approved")
    }
    return medias
}


/**
 * Fn checks whether specified media list contains rejected content
 * @param mediaList
 * @returns {boolean}
 */
export const checkSensitiveContents = (mediaList = []) => {
    let result = false
    for (const mediaListElement of mediaList) {
        if (mediaListElement?.validationStatus === "rejected" && !mediaListElement?.toBeDeleted) {
            result = true
        }
    }
    return result
}

export const wait = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export const setMainImageIfNotSet = (medias) => {
    let bulkImages = medias["bulkImages"]
    const mainImageExists = bulkImages.find(x=>x.mainImage&&(!x.toBeDeleted)&&(x?.validationStatus!=="rejected"))
    // Return media list if mainImage exists
    if(mainImageExists){
        return medias
    }
    // If main image not exists find an image that is not failed and deleted
    const newMainImageIndex = bulkImages.findIndex(x=>(!x.toBeDeleted)&&(x?.validationStatus!=="rejected"))
    if(newMainImageIndex!==-1){
        for (let currentIndex = 0; currentIndex < bulkImages.length; currentIndex++) {
            medias["bulkImages"][currentIndex].mainImage=newMainImageIndex===currentIndex;
        }
    }
    return medias
}

/**
 * Fn to limit uploaded data-transfer object
 * @param files
 * @param limit
 * @returns {{isLimitExceeded: boolean, files}}
 */
export const limitUploadedFiles = ({files,limit}) => {
    log.debug({files,limit});
    let dataTransfer = new DataTransfer();
    const isLimitExceeded = files.length > limit
    const allowableCount=files.length > limit?limit:files.length
    for(let i = 0; i < allowableCount; i++) {
        log.debug({fileType:(typeof files[i])})
        dataTransfer.items.add(files[i]);
    }
    return {files:dataTransfer.files, isLimitExceeded}
}