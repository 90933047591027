const SET_QUESTIONS_DATA="SET_QUESTIONS_DATA"
const LOADING_TOGGLE_ACTION="LOADING_TOGGLE_ACTION"
const SET_QUESTIONS_TYPE="SET_QUESTIONS_TYPE"
const SET_QUESTIONS_STATUS="SET_QUESTIONS_STATUS"
const SET_QUESTIONS_REDIRECT="SET_QUESTIONS_REDIRECT"
const SET_QUESTIONS_PAGES="SET_QUESTIONS_PAGES"
const CLEAR_QUESTIONS="CLEAR_QUESTIONS"
const SET_QUESTIONS_CARID="SET_QUESTIONS_CARID"

export {
    CLEAR_QUESTIONS,
    LOADING_TOGGLE_ACTION,
    SET_QUESTIONS_DATA,
    SET_QUESTIONS_TYPE,
    SET_QUESTIONS_STATUS,
    SET_QUESTIONS_REDIRECT,
    SET_QUESTIONS_PAGES,
    SET_QUESTIONS_CARID
}