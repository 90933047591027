import log from "loglevel";

export const openWidget=()=>{
    if(window.kommunicate){
        window.kommunicate.displayKommunicateWidget(true);
        window.kommunicate.openWidgetPreview()
    }
}

export const hideWidget=()=>{
    if(window.kommunicate){
        window.kommunicate.displayKommunicateWidget(false);
    }
}

export const logoutKommunicateSession=()=>{
    if(window.kommunicate){
        window.kommunicate.logout()
    }
}

export const updateUserProfileToKommunicate = (userInfo,forceUpdate=false) =>{
    try{
        let lastSyncData = localStorage.getItem("kommunicateSyncInfo")
        lastSyncData = JSON.parse((lastSyncData||"{}"));
        // If last sync data is of same user skip profile sync
        if(lastSyncData?.userId===userInfo.userId&&!forceUpdate){
            return
        }
        window.kommunicate.updateUser(userInfo);
        localStorage.setItem("kommunicateSyncInfo",JSON.stringify(userInfo))
    }catch(e){
        log.error(e)
    
    }
}