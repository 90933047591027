import { Amplify, Hub } from "aws-amplify";
import "bootstrap/dist/css/bootstrap.min.css";

import "./styles/style.scss";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { amplifyEventListener } from "./redux/auth";

import "@aws-amplify/ui-react/styles.css";
import aws_exports from "./aws-exports";
import RouteList from "./routes";
import SentryFallback from "./components/Fallback/SentryFallback";
import * as Sentry from "@sentry/react";

Amplify.configure(aws_exports);

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const listener = async (data) => {
    dispatch(amplifyEventListener(data, navigate));
  };
  Hub.listen("auth", listener);
  return (
    <>
      <Sentry.ErrorBoundary fallback={<SentryFallback />}>
        <RouteList />
        <ToastContainer style={{ "z-index": "200000" }} />
      </Sentry.ErrorBoundary>
    </>
  );
};

export default App;
