import { API, graphqlOperation } from "aws-amplify";
import {
  addedAccessories,
  getAccessoriesData,
  hpiMotDetailsApi,
  getVehicleRegId,
  getLikedCars,
  getCarsDetailsData,
  getPhotosAndVideos,
  getExteriorGrade,
  getCarSummaryDetails,
  getWheelsAndTyre,
  getAdditinalIngormationData,
  getCarCondition,
  carValueLimit,
  getTransmissionData,
  getManufactureData,
  myCars,
  expiredPremiumCars,
  filterData,
  getCars,
  otherCarsByUser,
  listCars,
  getPremiumStatusDetails,
  getListMaster,
  getActiveUserCount,
  premiumPaymentHistory,
  DashboardMyCars,
  getCarWithIssues,
  getMyMatches,
  exportStockList,
  getStockPrintList,
  getOtherUserCarList,
  getMaxAndMinPriceFilter,
  makeDataFilterQuery,
  getBodyTypes,
  getFuelTypes,
  getAvailableCarCount,
  getAvailableColors,
  getMileageQuery,
  getEngineSizeQuery,
  getYearFilterQuery,
} from "../graphql/queries/car";
import {
  addCarConfirmation,
  updateCarCondition,
  updateWheelsAndTyres,
  updateCarDetails,
  updateUserExpectedValue,
  updatePhotosAndVideos,
  updateCarServiceHistory,
  updateCarInstructedPhoto,
  addAdditionalInformation,
  createCar,
  updateCarHpiMotHistory,
  createCarNew,
  deleteCar,
  updateQuickSale,
  LikeACar,
  UnlikeACar,
  DislikeACar,
  updateCarStatusData,
  updateCarValuationDetails,
  listCarsAdd,
  createTransaction,
  updateTransactionStatus,
  updateTransaction,
  updateCarViews,
  updateOpenToSwap,
} from "../graphql/mutations/car";
import {
  getUserRegisteredDate,
  getCompanyDescription,
} from "../graphql/queries/user";
import {
  getAddCarDetails,
  getCarListingProps,
} from "../graphql/queries/car/addCarQueries";
import log from "loglevel";
import { getTraderByTraderId, getUserDetails } from "./auth.service";
import { addStockFilter } from "../components/viewCarFilter/other/addStockFilter";

const confirmYourCar = async (props) => {
  const response = await API.graphql(
    graphqlOperation(addCarConfirmation, props)
  );

  return response;
};

const getAccessories = async () => {
  try {
    const response = await API.graphql({
      query: getAccessoriesData,
    });
    return response.data;
  } catch (e) {
    log.error(e);
  }
};

const getVehicleReg = async (id) => {
  try {
    const response = await API.graphql({
      query: getVehicleRegId,
      variables: { id },
    });
    return response.data;
  } catch (e) {
    log.error(e);
  }
};

const getAddedAccessories = async (carId) => {
  const response = await API.graphql(
    graphqlOperation(addedAccessories, { id: carId })
  );
  return response;
};

const editCarCondition = async (carId, { createStatus, conditionAndDamage }) => {
  const response = await API.graphql(
    graphqlOperation(updateCarCondition, {
      id: carId,
      conditionAndDamage,
      createStatus
    })
  );
  return response;
};

const carPhotosAndVideos = async ({ carId, uploadPhotos, medias, createStatus }) => {
  const response = await API.graphql(
    graphqlOperation(updatePhotosAndVideos, {
      id: carId,
      uploadPhotos: uploadPhotos,
      medias,
      createStatus
    })
  );
  return response;
};

const getcarPhotosAndVideos = async ({ carId }) => {
  const response = await API.graphql({
    query: getPhotosAndVideos,
    variables: { id: carId },
  });

  return response.data;
};

const carWheelsAndTyres = async ({
  carId,
  lockWheelNut,
  scuffedAlloy,
  toolPack,
  tyreProblem,
  tyreImage,
  wheelImage,
}) => {
  const response = await API.graphql(
    graphqlOperation(updateWheelsAndTyres, {
      id: carId,
      wheelsAndTyres: {
        lockWheelNut,
        scuffedAlloy,
        toolPack,
        tyreProblem,
        tyreImage,
        wheelImage,
      },
    })
  );
  return response;
};

const getCarDetails = async ({ key, id }) => {
  const response = await API.graphql({
    query: getCarsDetailsData,
    variables: { key, id },
  });
  return response.data;
};

const getConditionAndDamages = async (carId) => {
  const response = await API.graphql({
    query: getCarCondition,
    variables: { id: carId },
  });
  return response.data;
};

const getCarWheelsAndTyres = async (carId) => {
  const response = await API.graphql({
    query: getWheelsAndTyre,
    variables: { id: carId },
  });
  return response.data;
};

const getUserRegistered = async ({ key, cognitoId }) => {
  const response = await API.graphql({
    query: getUserRegisteredDate,
    variables: { key, cognitoId },
  });

  return response.data;
};

const updateEditCarDetails = async (props) => {
  const {
    id,
    model,
    manufacturer,
    yearOfManufacture,
    fuelType,
    colour,
    engineSize,
    transmissionType,
    bodyType,
    doors,
  } = props;
  log.debug("yaa", transmissionType);

  const response = await API.graphql({
    query: updateCarDetails,
    variables: {
      id,
      model,
      manufacturer,
      yearOfManufacture,
      fuelType,
      colour,
      engineSize,
      transmissionType,
      bodyType,
      doors,
    },
  });
  return response;
};

const updateCarValuation = async (props) => {
  const { id, addedAccessories, userExpectedValue, quickSale, openToSwap } = props;

  const response = await API.graphql({
    query: updateCarValuationDetails,
    variables: {
      id,
      addedAccessories,
      userExpectedValue,
      quickSale,
      openToSwap
    },
  });
  return response;
};

const userExpectedValueChange = async ({ id, userExpectedValue }) => {
  const response = await API.graphql({
    query: updateUserExpectedValue,
    variables: { id, userExpectedValue },
  });
  return response;
};

const getHpiMotDetails = async (carId) => {
  const response = await API.graphql(
    graphqlOperation(hpiMotDetailsApi, { id: carId })
  );
  return response;
};

const likedCarsData = async ({ userId, filters, pageNo, perPage }) => {
  const response = await API.graphql({
    query: getLikedCars,
    variables: { pageNo, perPage, userId, filter: filters },
  });
  return response.data;
};
const likedUserCarsData = async ({ userId, filters={}, pageNo, perPage }) => {
  let user = await getUserDetails({ key: "_id", value: userId })
  let userInfo = user?.getUserDetails;
  if (userInfo?.userType !== "private") {
    let { getDealer } = await getTraderByTraderId(user?.getUserDetails?.traderId)
    userInfo.trader = getDealer;
  } else {
    userInfo.trader = {}
  }
  const response = await API.graphql({
    query: getOtherUserCarList,
    variables: {
      pageNo,
      perPage,
      userIdOrTraderId: userInfo?.userType === "private" ? userInfo?._id : userInfo?.traderId,
      userType: userInfo?.userType === "private" ? "private" : "dealer",
      filters
    },
  });
  let res = response.data
  res.otherUser = userInfo;
  return res;
};

const updateServiceHistory = async (carId, { serviceHistory, createStatus }) => {
  const response = await API.graphql(
    graphqlOperation(updateCarServiceHistory, {
      id: carId,
      serviceHistory,
      createStatus
    })
  );
  return response;
};

const updateHpiMotHistory = async (carId, { hpiAndMot, createStatus }) => {
  const response = await API.graphql(
    graphqlOperation(updateCarHpiMotHistory, {
      id: carId,
      hpiAndMot,
      createStatus
    })
  );
  log.debug("response", response);
  return response;
};

const instructedPhoto = async (id, instructedPhoto) => {
  const response = await API.graphql({
    query: updateCarInstructedPhoto,
    variables: { id, instructedPhoto },
  });
  return response;
};

const getCarExteriorGrades = async () => {
  try {
    const response = await API.graphql({
      query: getExteriorGrade,
    });
    return response.data.getExteriorGrade;
  } catch (e) {
    log.error(e);
  }
};

const mapListToOptions = (list = [], labelField = "name") => {
  return list.map((item) => {
    return {
      label: item[labelField],
      value: item._id,
      id: item._id,
      name: item[labelField],
    };
  });
};

export const getCarListingOptions = async () => {
  try {
    const response = await API.graphql({
      query: getCarListingProps,
    });
    const props = response?.data?.listMasterTechnicalDetails || {};
    const carColors = mapListToOptions(props.carColors || []);
    const manufacturers = mapListToOptions(props.manufacturers || []);
    const transmissionTypes = mapListToOptions(props.transmissionTypes || []);
    const fuelTypes = mapListToOptions(props.fuelTypes || []);
    const bodyTypes = mapListToOptions(props.bodyTypes || []);
    const doors = props.doors || [];
    log.debug({
      props,
      carColors,
      manufacturers,
      transmissionTypes,
      fuelTypes,
      bodyTypes,
      doors,
    });
    return {
      carColors,
      manufacturers,
      transmissionTypes,
      fuelTypes,
      bodyTypes,
      doors,
    };
  } catch (e) {
    log.error(e);
  }
};

export const getCarExteriorGradeOptions = async () => {
  try {
    let options = [];
    const result = await getCarExteriorGrades();
    options = result?.map(function (res) {
      return { value: res.percentageValue, label: res.grade, id: res._id };
    });
    return options;
  } catch (e) {
    log.error(e);
  }
};

const companyDescription = async ({ key, cognitoId }) => {
  const response = await API.graphql({
    query: getCompanyDescription,
    variables: { key, cognitoId },
  });
  return response.data;
};

const carSummaryData = async ({ key, carSlugId }) => {
  const response = await API.graphql({
    query: getCarSummaryDetails,
    variables: { key, carSlugId },
  });
  return response.data;
};

const getUsersCount = async () => {
  const response = await API.graphql({
    query: getActiveUserCount,
  });
  return response.data;
};

const additionalInformation = async (
  { attentionGrabber, description, companyDescription, createStatus },
  carSlugId
) => {
  const response = await API.graphql({
    query: addAdditionalInformation,
    variables: {
      additionalInformation: {
        attentionGraber: attentionGrabber,
        description,
        companyDescription,
      },
      createStatus,
      carSlugId,

    },
  });
  return response.data;
};

const getAdditionalInformation = async ({ key, carSlugId }) => {
  const response = await API.graphql({
    query: getAdditinalIngormationData,
    variables: { key, carSlugId },
  });
  return response.data;
};

const getCarValueLimit = async () => {
  const response = await API.graphql({
    query: carValueLimit,
  });
  return response.data;
};

const transmissionData = async () => {
  const response = await API.graphql({
    query: getTransmissionData,
  });
  return response.data;
};

const createNewCar = async (props) => {
  const {
    model,
    manufacturer,
    yearOfManufacture,
    firstRegistered,
    fuelType,
    colour,
    engineSize,
    transmissionType,
    extraAccessories,
    addedAccessories,
    vin,
    milege,
    exteriorGradeId,
    wsacValue,
    userExpectedValue,
    tradeValue,
    retailValuation,
    registration,
    userId,
    quickSale,
    status,
    userType,
  } = props;

  const response = await API.graphql({
    query: createCar,
    variables: {
      model,
      manufacturer,
      yearOfManufacture,
      firstRegistered,
      fuelType,
      colour,
      engineSize,
      transmissionType,
      extraAccessories,
      addedAccessories,
      vin,
      milege,
      exteriorGradeId,
      wsacValue,
      userExpectedValue,
      tradeValue,
      retailValuation,
      registration,
      userId,
      quickSale,
      status,
      userType,
    },
  });
  return response.data;
};

const createNewSchemaCar = async (props) => {
  const {
    model,
    manufacturer,
    yearOfManufacture,
    fuelType,
    colour,
    engineSize,
    transmissionType,
    extraAccessories,
    addedAccessories,
    mileage,
    exteriorGrade,
    wsacValue,
    userExpectedValue,
    tradeValue,
    retailValuation,
    registration,
    userId,
    quickSale,
    userType,
    createStatus,
    carLocation,
    additionalInformation,
    bodyType,
    doors,
    hpiMot,
    thirdPartyAPI,
    openToSwap,
    catDetails
  } = props;

  const response = await API.graphql({
    query: createCarNew,
    variables: {
      registration,
      mileage,
      exteriorGrade,
      userId,
      userType,
      model,
      manufacturer,
      yearOfManufacture,
      fuelType,
      colour,
      engineSize,
      transmissionType,
      addedAccessories: {
        notListed: extraAccessories,
        listed: addedAccessories,
      },
      wsacValue,
      userExpectedValue,
      tradeValue,
      retailValuation,
      quickSale,
      createStatus,
      carLocation,
      additionalInformation,
      bodyType,
      doors,
      hpiMot,
      thirdPartyAPI,
      openToSwap,
      catDetails
    },
  });
  return response.data;
};

const manufactureData = async (source,userId) => {
  const response = await API.graphql({
    query: getManufactureData,
    variables: {source,userId}
  });
  return response.data;
};

const removeCar = async (carSlugId) => {
  const response = await API.graphql({
    query: deleteCar,
    variables: carSlugId,
  });
  return response.data;
};

const addQuickSale = async ({ carSlugId, quickSaleValue, userExpectedValue }) => {
  const response = await API.graphql({
    query: updateQuickSale,
    variables: { carSlugId, quickSaleValue, userExpectedValue },
  });
  return response.data;
};

const addOpenToSwap = async ({ carSlugId, openToSwapValue }) => {
  const response = await API.graphql({
    query: updateOpenToSwap,
    variables: { carSlugId, openToSwapValue },
  });
  return response.data;
};

const getMyCars = async ({ filters, pageNo, perPage }) => {
  const response = await API.graphql({
    query: myCars,
    variables: { filter: filters, pageNo, perPage },
  });
  return response.data;
};

const getExpiredPremiumCars = async ({ pageNo, perPage }) => {
  const response = await API.graphql({
    query: expiredPremiumCars,
    variables: { pageNo, perPage },
  });
  return response.data;
};

const getFilterData = async (filterListedOnly = false) => {
  const response = await API.graphql({
    query: filterData,
    variables: { filterData: filterListedOnly }
  });
  let data = {};
  if (response?.data?.listMasterTechnicalDetails) {
    let _data = response.data.listMasterTechnicalDetails;
    data = {
      ..._data,
      bodyType: _data.bodyTypes,
      transmissionType: _data.transmissionTypes,
      fuelType: _data.fuelTypes,
      makers: _data.manufacturers,
      doors: _data.doors,
      transmissionTypesGrouped: _data.transmissionTypesGrouped,
      fuelTypesGrouped: _data.fuelTypesGrouped,
    };
  }
  return data;
};

const getMatchedCars = async (props) => {
  const { filter, filterRecentCars, pageNo } = props;
  const response = await API.graphql({
    query: getCars,
    variables: { filter, filterRecentCars, pageNo },
  });
  return response.data;
};
export const getMatchedCarsRetry = async (
  props,
  { retries = 3, backoff = 300 }
) => {
  const { filter, filterRecentCars, pageNo, paginationKey } = props;
  try {
    const response = await API.graphql({
      query: getCars,
      variables: {
        filter,
        filterRecentCars,
        pageNo,
        ...(paginationKey && { paginationKey }),
      },
    });
    return response.data;
  } catch (err) {
    if (retries > 0) {
      setTimeout(() => {
        return getMatchedCarsRetry(props, {
          retries: retries - 1,
          backoff: backoff * 2,
        });
      }, backoff);
    }
  }
};

const getOtherCarsByUser = async ({ userId, exclude, pageNo, perPage, filters }) => {
  const response = await API.graphql({
    query: otherCarsByUser,
    variables: { userId, exclude, pageNo, perPage, filters },
  });
  return response.data;
};

const likeACar = async ({ carId, ownerId, ownerType }) => {
  const response = await API.graphql({
    query: LikeACar,
    variables: { carId, ownerId, ownerType },
  });
  return response.data;
};
const dislikeACar = async ({ carId }) => {
  const response = await API.graphql({
    query: DislikeACar,
    variables: { carId },
  });
  return response.data;
};
const unLikeACar = async ({ carId, userId, deleteAll }) => {
  const response = await API.graphql({
    query: UnlikeACar,
    variables: { carId, userId, deleteAll },
  });
  return response.data;
};

const getPremiumPaymentHistory = async (id) => {
  const response = await API.graphql({
    query: premiumPaymentHistory,
    variables: { id },
  });
  return response.data;
};

const getQuickSaleData = async ({ filters, pageNo, perPage }) => {
  // filters.listQuickSaleOnly = true;
  const response = await API.graphql({
    query: listCars,
    variables: { pageNo, perPage, sort: -1, filter: { ...filters, listQuickSaleOnly: true } },
  });
  return response.data;
};
export const unlikeCars = async (props) => {
  const response = await API.graphql(graphqlOperation(UnlikeACar, props));
  return response;
};
const getPremiumStatus = async (id) => {
  const response = await API.graphql({
    query: getPremiumStatusDetails,
    variables: { id },
  });
  return response.data;
};

export const loadAddCarPreview = async (id) => {
  try {
    const response = await API.graphql({
      query: getAddCarDetails,
      variables: { id },
    });
    return response.data;
    //
  } catch (e) {
    log.error(e);
    return null;
  }
};

export const getListAccessories = async () => {
  const response = await API.graphql({
    query: getListMaster,
  });
  return response.data;
};

const updateCarStatus = async ({ carSlugId, createStatus }) => {
  const response = await API.graphql({
    query: updateCarStatusData,
    variables: { carSlugId, createStatus },
  });
  return response.data;
};

const carListCarAddCar = async (props) => {
  const { id, status, userId, userType } = props;
  log.debug("inside", id, status, userId, userType);
  const response = await API.graphql({
    query: listCarsAdd,
    variables: {
      id,
      status,
      userId,
      userType,
    },
  });
  return response;
};

const getDashboardMyCars = async ({ pageNo, perPage, filter }) => {
  const response = await API.graphql({
    query: DashboardMyCars,
    variables: { pageNo, perPage, filter }
  })
  return response
}

const carWithIssues = async ({ pageNo, perPage }) => {
  const response = await API.graphql({
    query: getCarWithIssues,
    variables: { perPage, pageNo }
  })
  return response.data
}
const getMyMatchesList = async ({ pageNo, perPage, userId }) => {
  const response = await API.graphql({
    query: getMyMatches,
    variables: { pageNo, perPage, userId }
  })
  return response?.data?.getMyMatches
}

const createTransactions = async (props) => {
  const response = await API.graphql({
    query: createTransaction,
    variables: props
  })
  return response?.data
}

const updateTransactions = async (props) => {
  const response = await API.graphql({
    query: updateTransaction,
    variables: props
  })
  return response?.data
}

const updateTransactionsStatus = async (props) => {
  const response = await API.graphql({
    query: updateTransactionStatus,
    variables: props
  })
  return response?.data
}

const updateRetailValuation = async (props) => {
  const apiName = "AuthenticatedApi";
  const path = "/updateRetailValuation";
  const myInit = {
    body: props,
    headers: {},

  };
  const response = await API.post(apiName, path, myInit);
  return response;
}

const generateHashCode = async (props) => {
  const apiName = "AuthenticatedApi";
  const path = "/generate-hash";
  const myInit = {
    body: props,
    headers: {},

  };
  const response = await API.post(apiName, path, myInit);
  return response?.data?.responseParameter;
}

const exportStockListDownLoad = async ({ filter }) => {
  const response = await API.graphql({
    query: exportStockList,
    variables: { filter }
  })
  return response.data
}
const getStockPrint = async ({ filters }) => {
  const response = await API.graphql({
    query: getStockPrintList,
    variables: { filter: filters },
  });
  return response.data;
};

const updateCarViewCount = async (id) => {
  const response = await API.graphql({
    query: updateCarViews,
    variables: { carId: id },
  });
  return response.data;
};

const makeFilterData = async (filters={}) => {
  const response = await API.graphql({
    query: makeDataFilterQuery,
    variables: { filters },
  });
  return response.data;
};

const maxAndMinPriceFilterData = async (filters ={}) => {
  const response = await API.graphql({
    query: getMaxAndMinPriceFilter,
    variables: { filters },
  });
  return response.data;
};

const getBodyTypesData = async (filters ={}) => {
  const response = await API.graphql({
    query: getBodyTypes,
    variables: { filters },
  });
  return response.data;
};

const getFuelTypesData = async (filters ={}) => {
  const response = await API.graphql({
    query: getFuelTypes,
    variables: { filters },
  });
  return response.data;
};

const getAvailableCarCountData = async (filters ={}) => {
  filters = addStockFilter(filters);
  const response = await API.graphql({
    query: getAvailableCarCount,
    variables: { filters },
  });
  return response.data;
};

const getColorData = async (filters ={}) => {
  const response = await API.graphql({
    query: getAvailableColors,
    variables: { filters },
  });
  return response.data;
};

const getMileageData = async (filters = {}) => {
  const response = await API.graphql({
    query: getMileageQuery,
    variables: { filters },
  });
  return response.data;
};

const getEngineSizeData = async (filters = {}) => {
  const response = await API.graphql({
    query: getEngineSizeQuery,
    variables: { filters },
  });
  return response.data;
};

const getYearFilterData = async (filters = {}) => {
  const response = await API.graphql({
    query: getYearFilterQuery,
    variables: { filters },
  });
  return response.data;
};

export {
  dislikeACar,
  confirmYourCar,
  editCarCondition,
  carWheelsAndTyres,
  getCarWheelsAndTyres,
  getConditionAndDamages,
  getAccessories,
  getAddedAccessories,
  getCarDetails,
  getUserRegistered,
  updateEditCarDetails,
  userExpectedValueChange,
  carPhotosAndVideos,
  getcarPhotosAndVideos,
  getHpiMotDetails,
  updateHpiMotHistory,
  getVehicleReg,
  likedCarsData,
  likedUserCarsData,
  updateServiceHistory,
  instructedPhoto,
  getCarExteriorGrades,
  companyDescription,
  additionalInformation,
  carSummaryData,
  getAdditionalInformation,
  getCarValueLimit,
  transmissionData,
  createNewCar,
  manufactureData,
  removeCar,
  addQuickSale,
  getMyCars,
  getExpiredPremiumCars,
  getFilterData,
  getMatchedCars,
  getOtherCarsByUser,
  likeACar,
  unLikeACar,
  getQuickSaleData,
  getPremiumStatus,
  createNewSchemaCar,
  updateCarStatus,
  updateCarValuation,
  carListCarAddCar,
  getUsersCount,
  getPremiumPaymentHistory,
  getMyMatchesList,
  createTransactions,
  updateTransactions,
  updateTransactionsStatus,
  getDashboardMyCars,
  carWithIssues,
  updateRetailValuation,
  generateHashCode,
  exportStockListDownLoad,
  getStockPrint,
  updateCarViewCount,
  addOpenToSwap,
  makeFilterData,
  maxAndMinPriceFilterData,
  getBodyTypesData,
  getFuelTypesData,
  getAvailableCarCountData,
  getColorData,
  getMileageData,
  getEngineSizeData,
  getYearFilterData,
};
