
import { SET_UNDO_COUNT } from './type';

const initialState = {
    undoCount: 0,
  };

 const undoCountReducer = (state = initialState, action) => {
    if(action.type==SET_UNDO_COUNT){
        return {
                ...state,
                undoCount: action.payload,
              };
        }
        
        return state;
};

export default undoCountReducer;
