import React from 'react'


const Modal = (props) => {
    if (!props.show) {
        return null
    }
    return (
        <div className={`modal-wrap overlay ${props.className}`}>
            <div className='back-drop' onClick={props.closeModal}></div>
            {props.children}
        </div>
    )
}
export default Modal;
