
export function findHPICheck(hpi_check, setAllClear, allClear) {
    if (!hpi_check) {
      return {
        message: "Something went wrong",
        problem: []
      };
    }
  
    let problem = 0;
    const all_clear = allClear;
  
    function addProblemCheck(property, message) {
      if(property === "KeeperChangesQty"){
        if(parseInt(hpi_check[property]) > 0){
          problem++;
          all_clear[property] = {
            message: message,
            problem: true
          };
        }
      }else
      if (hpi_check[property]) {
        problem++;
        all_clear[property] = {
          message: message,
          problem: true
        };
      }
    }
  
    addProblemCheck("cherishedDataQty", "Private plate change");
    addProblemCheck("colourChangesQty", "Colour has been changed");
    addProblemCheck("financeDataQty", "On finance");
    addProblemCheck("highRiskDataQty", "High risk");
    addProblemCheck("stolenVehicleDataQty", "Stolen");
    addProblemCheck("isScrapped", "Scrapped");
    addProblemCheck("vehicleIdentityCheckQty", "Identity changed");
  
    setAllClear(all_clear);
  
    if (problem === 0) {
      return {
        message: "All clear",
        problem: all_clear
      };
    } else {
      let msg="There are markers"
      if(problem===1){
        msg="There is a marker"
      }
      return {
        message: msg,
        problem: all_clear
      };
    }
  }

export function findHpiWithoutValues(hpi_check){
  let message = "All clear"
  let problem = false
  let count = 0
  if(hpi_check){
    Object.entries(hpi_check).map(([key, value])=> {
      if(typeof(value) === "boolean"){
        if(value){
          count++
          problem = true
          message = "There are markers"//`There are ${count} Problems of car`
        }
      }else{
        if(value>0){
          count++
          problem = true
          message = "There are markers"//`There are ${count} Problems of car`
        }
      }
    })
  }
  if (count == 1) {
    message = "There is a marker"
  }

  
  return {
    message,
    problem
  }
}