import { API_NAMES } from "./lib/constants";
import {Auth} from "aws-amplify";

const aws_exports = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    mandatorySignIn: true,
    region: process.env.REACT_APP_USER_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN_URL,
      scope: ["email", "phone", "profile", "openid"],
      redirectSignIn: window.location.origin + "/",
      redirectSignOut: window.location.origin,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    authenticationFlowType: "CUSTOM_AUTH",
    federationTarget: "COGNITO_USER_POOLS",
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_USER_POOL_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
  },
  API: {
    endpoints: [
      {
        name: API_NAMES.PRIMARY,
        endpoint: "http://localhost:5000/api/v1",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          };
        },
      },
      {
        name: "AuthenticatedApi",
        endpoint: process.env.REACT_APP_API,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          };
        },
      },
      {
        name: "UnAuthenticatedApi",
        endpoint: process.env.REACT_APP_API,
      },
      {
        name: "NotificationApi",
        endpoint: process.env.REACT_APP_NOTIFICATION_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          };
        },
      },
    ],
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_USER_POOL_REGION,
  //aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
  firebase_app_key: process.env.REACT_APP_API_KEY,
};

export default aws_exports;
