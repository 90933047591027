import { COUNTRY_CODE } from "../lib/constants";
import { phoneRegex } from "../lib/manageFields";
import { checkPhoneInputAllZeros } from "../lib/customFunctions";

/**
 * Validates phone 10 &11 digit phone numbers
 * @param number
 * @returns {boolean}
 */
export const isValidPhoneNumber = (number) => {
    //if 11 digits are zero reject
    if(checkPhoneInputAllZeros(number)){
        return false
    }
    // If 10 digit number && starting with 0 reject
    if (number.length === 10 && number[0] === "0") {
        return false
    }
    // If 11 digit number && starting with 0 reject
    if (number.length === 11 && number[0] !== "0") {
        return false
    }
    // if 10 digit number append +44 to match regex
    if (number.length === 10) {
        number = COUNTRY_CODE + number
    }
    // Test with international matching
    return phoneRegex.test(number)
}

/**
 * Removes preceding zero from phone number
 * @param number
 * @returns {string}
 */
export const cleanPhoneNumber = (number) => {
    if (number?.includes("NaN")) {
        return ""
    }
    if (number !== "" && number !== undefined) {
        if (number.startsWith("0")) {
            number= number.slice(1);
        }
        number = parseInt(number).toString()
        let code = COUNTRY_CODE.replace("+", '')
        if (number.startsWith(code)) {
            return number.replace(code, "")
        }
        return number 
    } else {
        return number
    }
}

export const showCleanPhoneNumber = (pn) => {
    if (pn?.includes("NaN")) {
        return ""
    }
    if (pn !== "" && pn !== undefined) {
        let code = COUNTRY_CODE.replace("+", '')
         if (parseInt(pn).toString()?.startsWith(code)) {
            return pn?.replace(code, "")
        }
        pn= pn?.startsWith(code)? pn?.replace(code, ""): pn;
        const totalInputLength=pn.length;
        //WSAC-3168
        pn = parseInt(pn).toString().padStart(totalInputLength, '0'); //// Pads with leading zeroes
        return pn
    } else {
        return pn
    }
}