import * as questionTypes from "./types";

const initialState={
    questionType: "",
    questionStatus: "",
    questionRedirect:"",
    pageName: "",
    carId: "",
    showLoading:false
}

const questionReducer = (state=initialState, action={})=>{
    switch(action.type){
        case questionTypes.SET_QUESTIONS_DATA:
            return {
                ...state,
                ...action.payload
            }
        case questionTypes.LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: action.payload,
            };        
        case questionTypes.SET_QUESTIONS_TYPE:
            return {
                ...state,
                questionType: action.payload,
            }
        case questionTypes.SET_QUESTIONS_STATUS:
            return {
                ...state,
                questionStatus: action.payload,
            }
        case questionTypes.SET_QUESTIONS_REDIRECT:
            return {
                ...state,
                questionRedirect: action.payload,
            }
        case questionTypes.SET_QUESTIONS_PAGES:
            return {
                ...state,
                pageName: action.payload,
            }
        case questionTypes.CLEAR_QUESTIONS:
            localStorage.removeItem("questionRequest");
            return {
                questionType: "",
                questionStatus: "",
                questionRedirect:"",
                pageName: "",
                carId: "",
                showLoading:false
            };
        default:
            return state;
        }
    
    }
    
 export default questionReducer;