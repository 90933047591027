import React from "react";
import Modal from "../reusable/Modal";
import Button from "../reusable/Button";
import { InformationIcon } from "../icons/InformationIcon";

const InformationModal = (props) => {
  return (
    <Modal
      show={props.show}
      closeModal={props.closeModal}
      className="thankYou-modal"
    >
      <div className="modal-body">
        <div className="modal-head">
          <span className="icon">
            <InformationIcon />
          </span>
          <h3>INFORMATION</h3>
        </div>
        <div className="modal-content">
          <p>{props.content}</p>
        </div>
        <div className="modal-foot">
          <Button onClick={props.action}>{props.label}</Button>
        </div>
      </div>
    </Modal>
  );
};
export default InformationModal;
