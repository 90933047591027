export const emailRegex =
  /^[a-zA-Z0-9"/][a-zA-Z0-9.!#$%&'"*+/=?^_`{|}~-]+@[a-zA-Z0-9[\].!#$%&'"*+/=?^_`{|}~-]+\.[a-zA-Z0-9[\].!#$%&'"*+/=?^_`{|}~-]{2,10}$/;
export const phoneRegexInd = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const phoneRegexUk = /^\+?44\d{10}$/;
export const carRegNumberRegex = /^.{4}\B/;
export const numbersAndCommasRegex = /^[0-9,]+$/;
export const numbersAndAlphRegex = /^[a-zA-Z0-9\b\s]+$/;
export const milesRegex = /[a-zA-Z0-9]/;
export const specialCharRegex = /^[a-zA-Z0-9\s]+$/;
export const noSpaces = /^\S*$/;
export const numbersAndSpaceRegex = /^[0-9\s]*$/;
export const specialChars = /[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g;
export const AlphaSpaceRegex = /^[a-zA-Z ]*$/;
export const CompanyNameRegex = /^[-a-zA-Z./ ]*$/;
export const phoneRegex = /^(?:\+44|\+91|0)(?:(?:\d{3}){2}\d{4}|\d{10})$/;
export const phoneRegexSpaceFormat = /(\d{4})/;
export const firstNameFormat=/^[a-zA-Z\\-]+$/;
export const lastNameFormat=/^[a-zA-Z\s-]+$/;
export const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
