import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Component resets page to top of the window for shallow routings
 * @returns {null}
 * @constructor
 */
export default function ScrollTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}