export function formatTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours < 12 ? "AM" : "PM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${period}`;
}

export function convertFirebaseTimeStamp(timestamp) {
  return timestamp ? timestamp.toDate() : "";
}

export function formatDate(timestamp) {
  const date = new Date(timestamp);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  }

  const month = date.toLocaleString("en-US", { month: "long" });
  const formattedDate = `${month} ${date.getDate().toString()},${date.getFullYear()}`;
  return formattedDate;
}

export function convertDuration(duration) {
  const minutes = Math.floor(duration / 60);
  const remainingSeconds = duration % 60;
  let min = minutes > 0 ? `${minutes}m ` : "";
  return `${min}${remainingSeconds}s`;
}
