import * as dashboardActionTypes from "./types";

const initialState = {
  lastRefreshTime: new Date().getTime(),
  emptySlotAlert:false
};

const dashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case dashboardActionTypes.SET_DASHBOARD_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case dashboardActionTypes.RELOAD_DASHBOARD_DATA:
      return {
        ...state,
        lastRefreshTime: new Date().getTime(),
      };
    default:
      return state;
  }
};

export default dashboardReducer;
