const SET_CHATS_DATA="SET_CHATS_DATA"
const LOADING_TOGGLE_ACTION="LOADING_TOGGLE_ACTION"
const SET_CHATS_TYPE="SET_CHATS_TYPE"
const SET_CHATS_STATUS="SET_CHATS_STATUS"
const SET_CHATS_REDIRECT="SET_CHATS_REDIRECT"
const SET_CHATS_PAGES="SET_CHATS_PAGES"
const CLEAR_CHATS="CLEAR_CHATS"
const SET_CHATS_CARID="SET_CHATS_CARID"
const SET_CHATS_CARSLUGID = "SET_CHATS_CARSLUGID"
const SET_CHATS_USERLIST= "SET_CHATS_USERLIST"
const SET_CHATS_NOTIFICATION="SET_CHATS_NOTIFICATION"
const SET_CHATS_NOTIFICATION_BUTTON="SET_CHATS_NOTIFICATION_BUTTON"

export {
    CLEAR_CHATS,
    LOADING_TOGGLE_ACTION,
    SET_CHATS_DATA,
    SET_CHATS_TYPE,
    SET_CHATS_STATUS,
    SET_CHATS_REDIRECT,
    SET_CHATS_PAGES,
    SET_CHATS_CARID,
    SET_CHATS_CARSLUGID,
    SET_CHATS_USERLIST,
    SET_CHATS_NOTIFICATION,
    SET_CHATS_NOTIFICATION_BUTTON,
}