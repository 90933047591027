import { API } from "aws-amplify";
import { updateNotificationSettings } from "../graphql/mutations/user";

const notificationAPI = async (props) => {
  const path = `/notification/dispatch-notification`;
  const apiName = "AuthenticatedApi";
  const myInit = {
    body: props,
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const notificationAPISingleChat = async (props) => {
  const apiName = "AuthenticatedApi";
  const path = `/notification/dispatch-notification`;
  const myInit = {
    body: props,
    headers: {},
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const notificationFirebaseAPI = async (props) => {
  const apiName = "NotificationApi";
  const path = `/wsac-notification-${process.env.REACT_APP_FIREBASE_PROJECT_ID}-savePlayerIdFirebase`;
  const myInit = {
    body: props,
    headers: {},
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const notificationFirebaseLogoutAPI = async (props) => {
  const apiName = "NotificationApi";
  const path = `/wsac-notification-${process.env.REACT_APP_FIREBASE_PROJECT_ID}-deletePlayerIdFirebase`;
  const myInit = {
    body: props,
    headers: {},
  };
  const response = await API.post(apiName, path, myInit);
  return response;
};

const updateNotificationSettingsStatus = async ({ status, userId }) => {
  const notificationType = "notifications";
  const response = await API.graphql({
    query: updateNotificationSettings,
    variables: { notificationType, status, userId },
  });
  return response.data;
};

export {
  notificationAPI,
  notificationFirebaseAPI,
  notificationFirebaseLogoutAPI,
  updateNotificationSettingsStatus,
  notificationAPISingleChat,
};
