import OneSignal from "react-onesignal";
import log from "loglevel";

export default async function runOneSignal(id) {
  try {
    OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_APPID,
      allowLocalhostAsSecureOrigin: true,
    }).then(async () => {
      OneSignal.Debug.setLogLevel("trace");
      OneSignal.login(id);
      OneSignal.User.addAlias(id, "1");
      await OneSignal.Notifications.requestPermission();
    });
  } catch (error) {
    log.error("err", error);
  }
}

// Function to fetch the Player ID based on user _id
export async function fetchPlayerId(userId) {
  try {
    const player = await OneSignal.getPlayer(userId);
    return player?.id || null;
  } catch (error) {
    log.error("Error fetching Player ID:", error);
    return null;
  }
}

export async function logOutOneSignal() {
  try {
    await OneSignal.logout();
  } catch (error) {
    log.error("err", error);
  }
}

export async function initialSubscriptionOneSignal() {
  try {
    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(function (OneSignal) {
      OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APPID,
        safari_web_id:
          "web.onesignal.auto.08b05e7f-114b-4b4f-a485-263bca659097",
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
      });
    });
  } catch (error) {
    log.error("err", error);
  }
}
