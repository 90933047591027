const SET_WHEELS_TYRE_DETAILS="SET_WHEELS_TYRE_DETAILS"
const SET_MOT_DETAILS="SET_MOT_DETAILS"

const UPDATE_CAR_ID="UPDATE_CAR_ID";
const UPDATE_ADDITIONAL_FILTER_DATA = "UPDATE_ADDITIONAL_FILTER_DATA"
const RESET_ADDITIONAL_FILTER_DATA = "RESET_ADDITIONAL_FILTER_DATA"
export {
    SET_WHEELS_TYRE_DETAILS,
    SET_MOT_DETAILS,
    UPDATE_CAR_ID,
    UPDATE_ADDITIONAL_FILTER_DATA,
    RESET_ADDITIONAL_FILTER_DATA,
}