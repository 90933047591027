import { useCallback, useRef, useState } from "react";
import useLocalStorageState from "use-local-storage-state";

import {
  cleanup,
  generateState,
  getUrl,
  openPopup,
  saveState,
} from "../lib/tools";
import { OAUTH_RESPONSE } from "../lib/constants";
import { logOutOneSignal } from "../lib/OneSignal";

export const PROVIDERS = {
  TWITTER: "TWITTER",
  TIKTOK: "TIKTOK",
};
export const useOAuth2 = (props) => {
  const { provider, onSuccess, onError } = props;

  const popupRef = useRef();
  const intervalRef = useRef();
  const [{ loading, error }, setUI] = useState({
    loading: false,
    error: null,
  });
  const [data, setData, { removeItem, isPersistent }] = useLocalStorageState(
    `wsac-data`,
    {
      defaultValue: null,
    },
  );

  const getAuth = useCallback(() => {
    // 1. Init
    setUI({
      loading: true,
      error: null,
    });

    const state = generateState();
    // 2. Generate and save state
    if (provider !== PROVIDERS.TWITTER) {
      saveState(state);
    }

    // 3. Open popup
    popupRef.current = openPopup(getUrl(provider).oauth + "?state=" + state);

    // 4. Register message listener
    function handleMessageListener(message) {
      if (message.origin !== process.env.REACT_APP_BASE_URL) {
        return;
      }

      const type = message?.data?.type;
      if (type !== OAUTH_RESPONSE) {
        return;
      }
      try {
        if ("error" in message.data) {
          const errorMessage = message.data?.error || "Unknown Error occured.";
          setUI({
            loading: false,
            error: errorMessage,
          });
          if (onError) onError(errorMessage);
        } else {
          let payload = message?.data?.payload;
          setUI({
            loading: false,
            error: null,
          });
          setData(payload);
          if (onSuccess) {
            onSuccess(payload);
          }
        }
      } catch (genericError) {
        setUI({
          loading: false,
          error: genericError.toString(),
        });
      } finally {
        // Clear stuff ...
        cleanup(intervalRef, popupRef, handleMessageListener);
      }
    }
    window.addEventListener("message", handleMessageListener);

    // 4. Begin interval to check if popup was closed forcefully by the user
    intervalRef.current = setInterval(() => {
      const popupClosed =
        !popupRef.current?.window || popupRef.current?.window?.closed;
      if (popupClosed) {
        // Popup was closed before completing auth...
        setUI((ui) => ({
          ...ui,
          loading: false,
        }));
        cleanup(intervalRef, popupRef, handleMessageListener);
      }
    }, 250);

    // 5. Remove listener(s) on unmount
    return () => {
      window.removeEventListener("message", handleMessageListener);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [provider, onSuccess, onError, setUI, setData]);

  const logout = useCallback(() => {
    removeItem();
    setUI({ loading: false, error: null });
    logOutOneSignal();
  }, [removeItem]);

  return { data, loading, error, getAuth, logout, isPersistent };
};
