function turnaryConditionAND(condition1, condition2, condition3, good, bad) {
    if (condition1 && condition2 && condition3) {
      return good;
    }
    return bad;
}

function turnaryConditionOR(condition1, condition2, condition3, good, bad) {
    if (condition1 || condition2 || condition3) {
      return good;
    }
    return bad;
}

function turnaryCondition(condition, good, bad) {
  if(condition){
    return good;
  }
  return bad
}
export {
    turnaryConditionAND,
    turnaryConditionOR,
    turnaryCondition
}